import { Component, OnInit, Inject, AfterViewInit, Input } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { Component as BrComponent } from '@bloomreach/spa-sdk/';
import { Page } from '@bloomreach/spa-sdk';
import get from 'lodash/get';
import { WINDOW } from '@ng-web-apis/common';
import { Logger } from '@utils/logger';
import { RouteHistoryService } from '@services';

const logger = Logger.getLogger('PageMetadataComponent');

@Component({
  selector: 'ft-page-metadata',
  template: ``,
  styleUrls: ['./page-metadata.component.scss'],
})
export class PageMetadataComponent implements OnInit {
  @Input() component!: BrComponent;
  @Input() page!: Page;

  constructor(
    private meta: Meta,
    private titleService: Title,
    @Inject(DOCUMENT) private documentRef: Document,
    @Inject(WINDOW) readonly windowRef: Window,
    private routeHistory: RouteHistoryService
  ) {}

  ngOnInit() {
    const page = this.component.getModels()?.pageMetadata;
    const site = this.component.getModels()?.siteMetadata;
    if (page && site) {
      if (!this.page.getComponent().getModels().pageData) {
        if (page.metaTitle) {
          this.titleService.setTitle(
            page.metaTitle +
              (site.appendedTitleText ? ' | ' + site.appendedTitleText : '')
          );
        } else if (this.page.getTitle()) {
          // Use default from page properties.
          this.titleService.setTitle(
            this.page.getTitle() +
              (site.appendedTitleText ? ' | ' + site.appendedTitleText : '')
          );
        }

        this.meta.updateTag({ name: 'description', content: page.description });
        this.meta.updateTag({ name: 'keywords', content: page.keywords });
        this.meta.updateTag({ name: 'language', content: site.language });
        this.meta.updateTag({
          property: 'og:title',
          content:
            page.socialTitle && page.socialTitle !== ''
              ? page.socialTitle
              : page.metaTitle,
        });
        this.meta.updateTag({ name: 'og:site_name', content: site.siteName });
        this.meta.updateTag({
          property: 'og:description',
          content:
            page.socialDescription && page.socialDescription !== ''
              ? page.socialDescription
              : page.description,
        });
        this.meta.updateTag({
          name: 'twitter:site',
          content: site.twitterSite,
        });
        this.meta.updateTag({
          name: 'twitter:card',
          content: site.twitterCard,
        });
        // this.meta.updateTag({ name: 'viewport', content: site.viewport }, `name='viewport'`);

        if (page.image) {
          this.meta.addTag({
            property: 'og:image',
            content: '/site/binaries' + page.image,
          });
        }

        // Used on the 404 not found page, so Prerender returns a real 404 to bots.
        if (page.prerenderStatusCode) {
          let referrerUrl = '';
          const history: string[] = this.routeHistory.getRouteHistory();
          if (history.length > 1) {
            referrerUrl = this.windowRef.origin + history[history.length - 2];
          } else {
            referrerUrl = this.windowRef.origin + history[history.length - 1];
          }
          logger.error(
            'Page not found, Url: ' +
              this.windowRef.location.href +
              ', Status Code: ' +
              page.prerenderStatusCode +
              ', Referrer Url: ' +
              referrerUrl
          );
          this.meta.addTag({
            name: 'prerender-status-code',
            content: page.prerenderStatusCode,
          });
        }

        // Have Prerender set an additional header:
        // prerender-status-code '301', prerender-header 'Location: http://www.example.com'
        if (page.prerenderHeader) {
          this.meta.addTag({
            name: 'prerender-header',
            content: page.prerenderHeader,
          });
        }

        const robotsContent: string[] = [];
        robotsContent.push(page.noIndex ? 'noindex' : 'index');
        robotsContent.push(page.noFollow ? 'nofollow' : 'follow');
        this.meta.addTag({ name: 'robots', content: robotsContent.join(', ') });

        this.setCanonical(page.canonicalURL);
        this.setFavicon(site.favicon);

        this.documentRef.documentElement.lang = site.language;
      }

      this.documentRef.documentElement.lang = site.language;

      const translations: { string: string } = this.component.getModels()
        ?.translations;
      if (translations) {
        for (const [locale, url] of Object.entries(translations)) {
          const link = this.documentRef.createElement('link');
          link.setAttribute('rel', 'alternate');
          link.setAttribute('hreflang', locale);
          link.setAttribute('href', url);
          this.documentRef.head.appendChild(link);
        }
      }
    }

    // Set default page title incase the above fails
    if (this.titleService.getTitle() === '') {
      this.titleService.setTitle('Franklin Templeton');
    }
  }

  private setCanonical(url: string): void {
    let link: HTMLLinkElement =
      this.documentRef.querySelector(`link[rel='canonical']`) || null;
    if (link == null) {
      link = this.documentRef.createElement('link') as HTMLLinkElement;
      link.setAttribute('rel', 'canonical');
      this.documentRef.head.appendChild(link);
    }
    if (url) {
      link.setAttribute('href', this.windowRef.location.origin + '/' + url);
    } else {
      link.setAttribute('href', this.windowRef.location.href);
    }
  }

  private setFavicon(url: string): void {
    if (url) {
      Array.from(this.documentRef.getElementsByTagName('link')).forEach(
        (link) => {
          if (link.rel === 'icon') {
            link.setAttribute('rel', 'shortcut icon');
            link.setAttribute('type', 'image/x-icon');
            link.setAttribute('href', url);
          }
        }
      );
    }
  }
}
