import { NgModuleRef } from '@angular/core';

/**
 * For one off components.
 * Note - not tested if you have more that one of the same component on the same page.
 * I suspect there might be a race condition and the second will not load but put an
 * error in the console.
 */
export class InteractiveContentComponentConfig {
  static readonly AUSTRALIA_FUND_PLATFORMS = new InteractiveContentComponentConfig(
    'AustraliaFundPlatforms',
    () =>
      import(
        /* webpackChunkName: "australia-fund-platforms" */ './australia-fund-platforms/australia-fund-platforms.module'
      )
  );

  static readonly CPREIF_PORTFOLIO_TOOL = new InteractiveContentComponentConfig(
    'CpreifPortfolioTool',
    () =>
      import(
        /* webpackChunkName: "cpreif-portfolio-tool" */ './cpreif-portfolio-tool/cpreif-portfolio-tool.module'
      )
  );

  static readonly FILTER_GRID = new InteractiveContentComponentConfig(
    'FilterGrid',
    () =>
      import(
        /* webpackChunkName: "filter-grid" */ './filter-grid/filter-grid.module'
      )
  );

  static readonly GIO_TOPIC_CARDS = new InteractiveContentComponentConfig(
    'GioTopicCards',
    () =>
      import(
        /* webpackChunkName: "gio-topic-cards" */ './gio/topic-cards/topic-cards.module'
      )
  );

  static readonly GIO_PORTFOLIO_IMPLICATIONS = new InteractiveContentComponentConfig(
    'GioPortfolioImplications',
    () =>
      import(
        /* webpackChunkName: "gio-portfolio-implications" */ './gio/portfolio-implications/portfolio-implications.module'
      )
  );

  static readonly POP_QUIZ = new InteractiveContentComponentConfig(
    'PopQuiz',
    () => import(/* webpackChunkName: "popquiz" */ './pop-quiz/pop-quiz.module')
  );

  static readonly RECESSION_DASHBOARD = new InteractiveContentComponentConfig(
    'RecessionDashboard',
    () =>
      import(
        /* webpackChunkName: "recession-dashboard" */ './recession-dashboard/recession-dashboard.module'
      )
  );
  static readonly STRATEGY_LISTING = new InteractiveContentComponentConfig(
    'StrategyList',
    () =>
      import(
        /* webpackChunkName: "strategy-spotlight" */ './strategy/strategy-listing/strategy-listing.module'
      )
  );

  static readonly STRATEGY_SPOTLIGHT = new InteractiveContentComponentConfig(
    'StrategySpotlight',
    () =>
      import(
        /* webpackChunkName: "strategy-spotlight" */ './strategy/strategy-spotlight/strategy-spotlight.module'
      )
  );
  static readonly STRATEGY_CHARACTERISTICS_DATA = new InteractiveContentComponentConfig(
    'StrategyCharacteristicsData',
    () =>
      import(
        /* webpackChunkName: "strategy-spotlight" */ './strategy/strategy-data/strategy-data.module'
      )
  );
  static readonly STRATEGY_PERFORMANCE_DATA = new InteractiveContentComponentConfig(
    'StrategyPerformanceData',
    () =>
      import(
        /* webpackChunkName: "strategy-spotlight" */ './strategy/strategy-data/strategy-data.module'
      )
  );
  static readonly STRATEGY_INVESTMENTS_DATA = new InteractiveContentComponentConfig(
    'StrategyInvestmentOptionsData',
    () =>
      import(
        /* webpackChunkName: "strategy-spotlight" */ './strategy/strategy-data/strategy-data.module'
      )
  );
  static readonly STRATEGY_PERFORMANCE_LISTING_MODULE = new InteractiveContentComponentConfig(
    'StrategyPerformanceList',
    () =>
      import(
        /* webpackChunkName: "strategy-performance-listing" */ './strategy/strategy-performance-listing/strategy-performance-listing.module'
      )
  );
  static readonly WHAT_HAPPENED_LAST_WEEK = new InteractiveContentComponentConfig(
    'WhatHappenedLastWeek',
    () =>
      import(
        /* webpackChunkName: "what-happened-last-week" */ './what-happened-last-week/what-happened-last-week.module'
      )
  );

  static readonly MUNI_MAP = new InteractiveContentComponentConfig(
    'MuniMap',
    () => import(/* webpackChunkName: "munimap" */ './muni-map/muni-map.module')
  );

  static readonly PCS_TOOL = new InteractiveContentComponentConfig(
    'PcsTool',
    () => import(/* webpackChunkName: "pcstool" */ './pcs-tool/pcs-tool.module')
  );

  static readonly US_INDEX_BANNER = new InteractiveContentComponentConfig(
    'USIndexBanner',
    () =>
      import(
        /* webpackChunkName: "us-index-banner" */ './us-index/us-index-banner/us-index-banner.module'
      )
  );

  static readonly US_INDEX_PERFORMANCE = new InteractiveContentComponentConfig(
    'USIndexPerformance',
    () =>
      import(
        /* webpackChunkName: "us-index-performance" */ './us-index/us-index-performance/us-index-performance.module'
      )
  );

  static readonly US_INDEX_TOP_CONSTITUENT = new InteractiveContentComponentConfig(
    'USIndexTopConstituents',
    () =>
      import(
        /* webpackChunkName: "us-index-top-constituents" */ './us-index/us-index-top-constituents/us-index-top-constituents.module'
      )
  );

  static readonly US_INDEX_ALLOCATIONS = new InteractiveContentComponentConfig(
    'USIndexAllocations',
    () =>
      import(
        /* webpackChunkName: "us-index-allocations" */ './us-index/us-index-allocations/us-index-allocations.module'
      )
  );

  static readonly CPREIF_LOCATION_MAP = new InteractiveContentComponentConfig(
    'CpreifLocationMap',
    () =>
      import(
        /* webpackChunkName: "cpreif-location-map" */ './cpreif-location-map/cpreif-location-map.module'
      )
  );

  static readonly MIFID_CALCULATOR = new InteractiveContentComponentConfig(
    'MifidCalculator',
    () =>
      import(
        /* webpackChunkName: "mifid-calculator" */ './mifid-calculator/mifid-calculator.module'
      )
  );

  static readonly FIXED_INCOME_FINE_TUNING = new InteractiveContentComponentConfig(
    'FixedIncomeFineTuning',
    () =>
      import(
        /* webpackChunkName: "fixed-income-fine-tuning" */ './fixed-income-fine-tuning/fixed-income-fine-tuning.module'
      )
  );

  static readonly GERMAN_CALCULATOR_ONE_TIME_INVESTMENT = new InteractiveContentComponentConfig(
    'GermanOneTimeInvestmentCalculator',
    () =>
      import(
        /* webpackChunkName: "german-calculator-one-time-investment" */ './german-calculator/gc-one-time-investment/gc-one-time-investment.module'
      )
  );

  static readonly GERMAN_CALCULATOR_SAVINGS_PLAN = new InteractiveContentComponentConfig(
    'GermanSavingsPlanCalculator',
    () =>
      import(
        /* webpackChunkName: "german-calculator-savings-plan" */ './german-calculator/gc-savings-plan/gc-savings-plan.module'
      )
  );

  static readonly GERMAN_CALCULATOR_WITHDRAWL_PLAN = new InteractiveContentComponentConfig(
    'GermanWithdrawalPlanCalculator',
    () =>
      import(
        /* webpackChunkName: "german-calculator-withdrawl-plan" */ './german-calculator/gc-withdrawl-plan/gc-withdrawl-plan.module'
      )
  );

  static readonly GERMAN_CALCULATOR_PENSION = new InteractiveContentComponentConfig(
    'GermanPensionCalculator',
    () =>
      import(
        /* webpackChunkName: "german-calculator-pension" */ './german-calculator/gc-pension/gc-pension.module'
      )
  );

  static readonly RETIREMENT_LIFESTYLE_TOOL = new InteractiveContentComponentConfig(
    'RetirementLifestyleTool',
    () =>
      import(
        /* webpackChunkName: "retirement-lifestyle-tool" */ './retirement-lifestyle-tool/retirement-lifestyle-tool.module'
      )
  );

  static readonly QUOTENTIAL_QUERSTIONNAIRE = new InteractiveContentComponentConfig(
    'QuotentialQuestionnaire',
    () =>
      import(
        /* webpackChunkName: "quotential-questionnaire" */ './quotential-questionnaire/quotential-questionnaire.module'
      )
  );

  static readonly DYNAMIC_DROPDOWN = new InteractiveContentComponentConfig(
    'DynamicDropdown',
    () =>
      import(
        /* webpackChunkName: "dynamic-dropdown-tool" */ './dynamic-dropdown/dynamic-dropdown.module'
      )
  );

  static readonly NEWS_FEEDS = new InteractiveContentComponentConfig(
    'NewsFeeds',
    () =>
      import(
        /* webpackChunkName: "news-feeds-tool" */ './news-feeds/news-feeds.module'
      )
  );

  static readonly EFFECTIVE_ANNUAL_COSTS = new InteractiveContentComponentConfig(
    'SAEffectiveAnnualCosts',
    () =>
      import(
        /* webpackChunkName: "effective-annual-costs" */ './effective-annual-costs/effective-annual-costs.module'
      )
  );

  static readonly NJBEST_CONTRIBUTION_CALCULATOR = new InteractiveContentComponentConfig(
    'NJBESTContributionCalculator',
    () =>
      import(
        /* webpackChunkName: "njbest-contribution-calculator" */ './njbest/contribution-calculator/contribution-calculator.module'
      )
  );

  static readonly NJBEST_GET_STARTED = new InteractiveContentComponentConfig(
    'NJBESTGetStarted',
    () =>
      import(
        /* webpackChunkName: "njbest-get-started" */ './njbest/get-started/get-started.module'
      )
  );

  static readonly NJBEST_INVESTMENT_OPTIONS = new InteractiveContentComponentConfig(
    'NJBESTInvestmentOptions',
    () =>
      import(
        /* webpackChunkName: "njbest-investment-options" */ './njbest/investment-options/investment-options.module'
      )
  );

  static readonly NJBEST_PORTFOLIO_SELECTOR = new InteractiveContentComponentConfig(
    'NJBESTPortfolioSelector',
    () =>
      import(
        /* webpackChunkName: "njbest-portfolio-selector" */ './njbest/portfolio-selector/portfolio-selector.module'
      )
  );

  static readonly NJBEST_TREASURY_MANAGED_PRICE_AND_PERFORMANCE = new InteractiveContentComponentConfig(
    'NJBestTreasuryManagedPortfolios',
    () =>
      import(
        /* webpackChunkName: "njbest-treasury-managed-price-and-performance" */ './njbest/treasury-managed-price-and-performance/treasury-managed-price-and-performance.module'
      )
  );

  static readonly LITERATURE_AUDIT = new InteractiveContentComponentConfig(
    'LiteratureAudit',
    () =>
      import(
        /* webpackChunkName: "literature-audit" */ './literature-audit/literature-audit.module'
      )
  );

  static readonly PRC_LITERATURE_AUDIT = new InteractiveContentComponentConfig(
    'PrcLiteratureAudit',
    () =>
      import(
        /* webpackChunkName: "prc-literature-audit" */ './literature-audit/prc-literature-audit.module'
      )
  );

  static readonly SIMPLE_HIGHCHARTS = new InteractiveContentComponentConfig(
    'SimpleHighcharts',
    () =>
      import(
        /* webpackChunkName: "simple-highcharts" */ './simple-highcharts/simple-highcharts.module'
      ),
    true
  );

  static readonly TIMELY_INVESTMENT_POSITIONING = new InteractiveContentComponentConfig(
    'TimelyInvestmentPositioning',
    () =>
      import(
        /* webpackChunkName: "timely-investment-positioning" */ './timely-investment-positioning/timely-investment-positioning.module'
      )
  );

  static readonly LOTTIE_IMAGE = new InteractiveContentComponentConfig(
    'LottieImage',
    () =>
      import(
        /* webpackChunkName: "lottie-image" */ './lottie-image/lottie-image.module'
      ),
    true
  );

  static readonly EMERGING_MARKETS_QUIZ = new InteractiveContentComponentConfig(
    'EmergingMarketsQuiz',
    () =>
      import(
        /* webpackChunkName: "emerging-markets-quiz" */ './emerging-markets-quiz/emerging-markets-quiz.module'
      )
  );

  static readonly RACE_CHART = new InteractiveContentComponentConfig(
    'RaceChart',
    () =>
      import(
        /* webpackChunkName: "race-chart" */ './race-chart/race-chart.module'
      ),
    true
  );

  static readonly SNAPSWAP_KYC_FORM = new InteractiveContentComponentConfig(
    'SnapswapKYCForm',
    () =>
      import(
        /* webpackChunkName: "snapswap-kyc-form" */ './snapswap-kyc-form/snapswap-kyc-form.module'
      )
  );
  static readonly ETF_DATA_TABLE = new InteractiveContentComponentConfig(
    'UCITSEtfDataTable',
    () =>
      import(
        /* webpackChunkName: "emerging-markets-quiz" */ './ucits-etf-data-table/ucits-etf-data-table.module'
      )
  );

  static readonly US_SERVICING_LATEST_FUND_DOCUMENTS = new InteractiveContentComponentConfig(
    'USServicingLatestFundDocuments',
    () =>
      import(
        /* webpackChunkName: "USServicingLatestFundDocuments" */ './us-servicing/us-servicing-latest-fund-documents/us-servicing-latest-fund-documents.module'
      )
  );

  static readonly US_SERVICING_LATEST_DOCUMENTS_CARD = new InteractiveContentComponentConfig(
    'USServicingLatestDocumentsCard',
    () =>
      import(
        /* webpackChunkName: "USServicingLatestDocumentsCard" */ './us-servicing/us-servicing-fund-documents-card/us-servicing-fund-documents-card.module'
      )
  );

  static readonly US_SERVICING_MY_INVESTMENTS = new InteractiveContentComponentConfig(
    'USServicingMyInvestments',
    () =>
      import(
        /* webpackChunkName: "us-servicing-my-investments" */ './us-servicing/us-servicing-my-investments/us-servicing-my-investments.module'
      ),
    true
  );

  static readonly US_SERVICING_PERFORMANCE = new InteractiveContentComponentConfig(
    'USServicingPerformance',
    () =>
      import(
        /* webpackChunkName: "us-servicing-performance" */ './us-servicing/us-servicing-performance/us-servicing-performance.module'
      )
  );

  static readonly US_SERVICING_PERFORMANCE2 = new InteractiveContentComponentConfig(
    'USServicingPerformance2',
    () =>
      import(
        /* webpackChunkName: "us-servicing-performance2" */ './us-servicing/us-servicing-performance2/us-servicing-performance2.module'
      )
  );

  static readonly US_SERVICING_BANNER = new InteractiveContentComponentConfig(
    'USServicingBanner',
    () =>
      import(
        /* webpackChunkName: "us-servicing-banner" */ './us-servicing/us-servicing-banner/us-servicing-banner.module'
      )
  );

  static readonly US_SERVICING_ACCESS_PROFILE = new InteractiveContentComponentConfig(
    'UsServicingAccessProfileComponent',
    () =>
      import(
        /* webpackChunkName: "us-servicing-access-profile" */ './us-servicing/us-servicing-access-profile/us-servicing-access-profile.module'
      )
  );

  static readonly US_SERVICING_FIRST_TIME_MODAL = new InteractiveContentComponentConfig(
    'UsServicingFirstTimeModalComponent',
    () =>
      import(
        /* webpackChunkName: "promo-modal" */ './promo-modal/promo-modal.module'
      )
  );

  static readonly US_SERVICING_SALES_TEAM_UTILITY_BAR = new InteractiveContentComponentConfig(
    'UsServicingSalesTeamUtilityBar',
    () =>
      import(
        /* webpackChunkName: "us-servicing-sales-team-utility-bar" */ './us-servicing/us-servicing-sales-team-utility-bar/us-servicing-sales-team-utility-bar.module'
      )
  );

  static readonly US_SERVICING_TEMP_ANONYMOUS_USER = new InteractiveContentComponentConfig(
    'USServicingTempAnonymousUser',
    () =>
      import(
        /* webpackChunkName: "us-servicing-temp-anonymous-user" */ './us-servicing/us-servicing-temp-anonymous-user/us-servicing-temp-anonymous-user.module'
      )
  );

  static readonly US_SERVICING_TEMP_USER_UTILITY_BAR = new InteractiveContentComponentConfig(
    'USServicingTempAnonymousUserUtilityBar',
    () =>
      import(
        /* webpackChunkName: "us-servicing-temp-user-utility-bar" */ './us-servicing/us-servicing-temp-user-utility-bar/us-servicing-temp-user-utility-bar.module'
      )
  );

  // PROMO_MODAL is using the same component s US_SERVICING_FIRST_TIME_MODAL. This is defined to not impact existing BR configuration.
  static readonly PROMO_MODAL = new InteractiveContentComponentConfig(
    'PromoModalComponent',
    () =>
      import(
        /* webpackChunkName: "promo-modal" */ './promo-modal/promo-modal.module'
      )
  );

  // Literature Code Generator from front end
  static readonly LITERATURE_CODE_GENERATOR = new InteractiveContentComponentConfig(
    'LitCodeGenerator',
    () =>
      import(
        /* webpackChunkName: "lit-code-generator" */ './lit-code-generator/lit-code-generator.module'
      )
  );

  // Strategy Key Stats Brandywine
  static readonly STRATEGY_KEY_STATS = new InteractiveContentComponentConfig(
    'StrategyKeyStats',
    () =>
      import(
        /* webpackChunkName: "strategy-key-stats" */ './strategy-key-stats/strategy-key-stats.module'
      )
  );

  /* Migrated Putnam Tools */
  static readonly PUTNAM_COLLEGE_SAVINGS_SELECTION = new InteractiveContentComponentConfig(
    'CollegeSavingsSelectionTool',
    () =>
      import(
        /* webpackChunkName: "college-savings-selection" */ './putnam/college-savings-selection-tool/college-savings-selection-tool.module'
      )
  );
  static readonly PUTNAM_CD_MUNI_BOND_CALC = new InteractiveContentComponentConfig(
    'CdMuniBondCalculator',
    () =>
      import(
        /* webpackChunkName: "cd-muni-bond-calculator" */ './putnam/cd-muni-bond-calculator/cd-muni-bond-calculator.module'
      )
  );
  static readonly PUTNAM_QUILT_CHART = new InteractiveContentComponentConfig(
    'QuiltChart',
    () =>
      import(
        /* webpackChunkName: "quilt-chart" */ './putnam/quilt-chart/quilt-chart.module'
      )
  );
  static readonly PUTNAM_TIME_NOT_TIMING_INFOGRAPHIC = new InteractiveContentComponentConfig(
    'TimeNotTiming',
    () =>
      import(
        /* webpackChunkName: "cd-muni-bond-calculator" */ './putnam/time-not-timing-infographic/time-not-timing-infographic.module'
      )
  );
  static readonly PUTNAM_ROTH_IRA_CONVERSION = new InteractiveContentComponentConfig(
    'RothIRAConversionEvaluator',
    () =>
      import(
        /* webpackChunkName: "cd-muni-bond-calculator" */ './putnam/roth-ira-conversion/roth-ira-conversion.module'
      )
  );

  static readonly AVAILABILITY_GUIDE = new InteractiveContentComponentConfig(
    'AvailabilityGuide',
    () =>
      import(
        /* webpackChunkName: "availability-guide" */ './product-availability/availability-guide/availability-guide.module'
      ),
    true
  );

  static readonly AVAILABILITY_GUIDE_HEADER = new InteractiveContentComponentConfig(
    'AvailabilityGuideHeader',
    () =>
      import(
        /* webpackChunkName: "availability-guide-header" */ './product-availability/availability-guide-header/availability-guide-header.module'
      ),
    true
  );

  static readonly AROUND_THE_CURVE_SUBSCRIPTION_FORM = new InteractiveContentComponentConfig(
    'AroundTheCurveSubscriptionForm',
    () =>
      import(
        /* webpackChunkName: "around-the-curve-subscription-form" */ './around-the-curve-subscription-form/around-the-curve-subscription-form.module'
      ),
    true
  );

  static readonly SPIKES_VOLATILITY_CHART = new InteractiveContentComponentConfig(
    'SpikesVolatilityChart',
    () =>
      import(
        /* webpackChunkName: "spikes-volatility-chart" */ './spikes-volatility-chart/spikes-volatility-chart.module'
      ),
    false
  );

  /**
   * Add any new config to the allConfigs array.
   */
  static allConfigs: InteractiveContentComponentConfig[] = [
    InteractiveContentComponentConfig.AUSTRALIA_FUND_PLATFORMS,
    InteractiveContentComponentConfig.CPREIF_PORTFOLIO_TOOL,
    InteractiveContentComponentConfig.FILTER_GRID,
    InteractiveContentComponentConfig.POP_QUIZ,
    InteractiveContentComponentConfig.RECESSION_DASHBOARD,
    InteractiveContentComponentConfig.WHAT_HAPPENED_LAST_WEEK,
    InteractiveContentComponentConfig.ETF_DATA_TABLE,
    InteractiveContentComponentConfig.MUNI_MAP,
    InteractiveContentComponentConfig.US_INDEX_BANNER,
    InteractiveContentComponentConfig.US_INDEX_PERFORMANCE,
    InteractiveContentComponentConfig.US_INDEX_TOP_CONSTITUENT,
    InteractiveContentComponentConfig.US_INDEX_ALLOCATIONS,
    InteractiveContentComponentConfig.CPREIF_LOCATION_MAP,
    InteractiveContentComponentConfig.PCS_TOOL,
    InteractiveContentComponentConfig.MIFID_CALCULATOR,
    InteractiveContentComponentConfig.FIXED_INCOME_FINE_TUNING,
    InteractiveContentComponentConfig.GERMAN_CALCULATOR_ONE_TIME_INVESTMENT,
    InteractiveContentComponentConfig.GERMAN_CALCULATOR_SAVINGS_PLAN,
    InteractiveContentComponentConfig.GERMAN_CALCULATOR_WITHDRAWL_PLAN,
    InteractiveContentComponentConfig.GERMAN_CALCULATOR_PENSION,
    InteractiveContentComponentConfig.GIO_PORTFOLIO_IMPLICATIONS,
    InteractiveContentComponentConfig.GIO_TOPIC_CARDS,
    InteractiveContentComponentConfig.RETIREMENT_LIFESTYLE_TOOL,
    InteractiveContentComponentConfig.QUOTENTIAL_QUERSTIONNAIRE,
    InteractiveContentComponentConfig.DYNAMIC_DROPDOWN,
    InteractiveContentComponentConfig.NEWS_FEEDS,
    InteractiveContentComponentConfig.EFFECTIVE_ANNUAL_COSTS,
    InteractiveContentComponentConfig.NJBEST_CONTRIBUTION_CALCULATOR,
    InteractiveContentComponentConfig.NJBEST_GET_STARTED,
    InteractiveContentComponentConfig.NJBEST_INVESTMENT_OPTIONS,
    InteractiveContentComponentConfig.NJBEST_PORTFOLIO_SELECTOR,
    InteractiveContentComponentConfig.LITERATURE_AUDIT,
    InteractiveContentComponentConfig.PRC_LITERATURE_AUDIT,
    InteractiveContentComponentConfig.NJBEST_TREASURY_MANAGED_PRICE_AND_PERFORMANCE,
    InteractiveContentComponentConfig.RACE_CHART,
    InteractiveContentComponentConfig.SIMPLE_HIGHCHARTS,
    InteractiveContentComponentConfig.LOTTIE_IMAGE,
    InteractiveContentComponentConfig.TIMELY_INVESTMENT_POSITIONING,
    InteractiveContentComponentConfig.EMERGING_MARKETS_QUIZ,
    InteractiveContentComponentConfig.SNAPSWAP_KYC_FORM,
    InteractiveContentComponentConfig.US_SERVICING_LATEST_FUND_DOCUMENTS,
    InteractiveContentComponentConfig.US_SERVICING_MY_INVESTMENTS,
    InteractiveContentComponentConfig.US_SERVICING_PERFORMANCE,
    InteractiveContentComponentConfig.US_SERVICING_PERFORMANCE2,
    InteractiveContentComponentConfig.US_SERVICING_BANNER,
    InteractiveContentComponentConfig.US_SERVICING_LATEST_DOCUMENTS_CARD,
    InteractiveContentComponentConfig.US_SERVICING_ACCESS_PROFILE,
    InteractiveContentComponentConfig.US_SERVICING_FIRST_TIME_MODAL,
    InteractiveContentComponentConfig.US_SERVICING_SALES_TEAM_UTILITY_BAR,
    InteractiveContentComponentConfig.US_SERVICING_TEMP_ANONYMOUS_USER,
    InteractiveContentComponentConfig.US_SERVICING_TEMP_USER_UTILITY_BAR,
    InteractiveContentComponentConfig.PROMO_MODAL,
    InteractiveContentComponentConfig.LITERATURE_CODE_GENERATOR,
    InteractiveContentComponentConfig.STRATEGY_KEY_STATS,
    InteractiveContentComponentConfig.STRATEGY_LISTING,
    InteractiveContentComponentConfig.STRATEGY_CHARACTERISTICS_DATA,
    InteractiveContentComponentConfig.STRATEGY_INVESTMENTS_DATA,
    InteractiveContentComponentConfig.STRATEGY_PERFORMANCE_DATA,
    InteractiveContentComponentConfig.STRATEGY_SPOTLIGHT,
    InteractiveContentComponentConfig.STRATEGY_PERFORMANCE_LISTING_MODULE,
    InteractiveContentComponentConfig.PUTNAM_COLLEGE_SAVINGS_SELECTION,
    InteractiveContentComponentConfig.PUTNAM_CD_MUNI_BOND_CALC,
    InteractiveContentComponentConfig.PUTNAM_QUILT_CHART,
    InteractiveContentComponentConfig.AVAILABILITY_GUIDE,
    InteractiveContentComponentConfig.AVAILABILITY_GUIDE_HEADER,
    InteractiveContentComponentConfig.PUTNAM_ROTH_IRA_CONVERSION,
    InteractiveContentComponentConfig.PUTNAM_TIME_NOT_TIMING_INFOGRAPHIC,
    InteractiveContentComponentConfig.AROUND_THE_CURVE_SUBSCRIPTION_FORM,
    InteractiveContentComponentConfig.SPIKES_VOLATILITY_CHART,
  ];

  loaded = false;
  moduleRef: NgModuleRef<unknown>; // cache loaded module in case we need it again.

  // returns matching config or undefined
  static getConfig(key: string): InteractiveContentComponentConfig {
    return this.allConfigs.find(
      (config: InteractiveContentComponentConfig): boolean => {
        return config.key === key;
      }
    );
  }

  private constructor(
    private readonly key: string,
    public readonly loader: any,
    public readonly multiple?: boolean
  ) {}
}
