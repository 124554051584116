import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SearchService } from '@frk/eds-components/lib/types/search-service.interface';
import { BehaviorSubject, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, map, retry, share, switchMap, tap } from 'rxjs/operators';
import {
  FtSearchOptions,
  FtSearchParams,
  FtSearchResults,
  FtSearchStatus,
  FtPreSearchResults,
} from '../interfaces/search.interface';

import { FtInsightsResults } from '../interfaces/insights.interface';

import { PageMonitor } from '@services/page-monitor';
import { StorageService } from '@services/storage.service';
import { Logger } from '@utils/logger';
import { generateUUID } from '@utils/text/string-utils';
import { GlobalConfigService, SegmentService } from '@services';
import { SegmentId } from '@types';
import { Router } from '@angular/router';
export const SEARCH_STORAGE_NAME_KEY = 'recent_search';
const logger = Logger.getLogger('FtSearchService');

export enum ArticleType {
  ARTICLE = 'article',
  PRESS_RELEASE = 'press-release',
}

export enum SearchCollection {
  FUNDS = 'funds',
  PAGES = 'pages',
  LITERATURE = 'literature',
  INSIGHT = 'insight',
}

@Injectable({
  providedIn: 'root',
})
export class FtSearchService implements SearchService {
  /**
   * observables receiving and emitting status response from search
   */
  private statusInput$: Subject<{ ftSearchStatusUrl: string }>;
  statusOutput$: Observable<FtSearchStatus>;

  /**
   * observable receiving/emmiting search term
   */
  private termInput$: Subject<FtSearchParams>;

  /**
   * observable receiving trigger to perform preSearch query
   */
  private preSearchInput$: Subject<FtSearchParams>;

  /**
   * observables emitting search results
   */
  searchOutput$: Observable<FtSearchResults>;

  /**
   * observables emitting pre-search results
   */
  preSearchOutput$: Observable<FtPreSearchResults>;

  /**
   * observable for onPageSearch component - input
   */
  private onPageSearchInput$: Subject<FtSearchParams>;

  /**
   * observables emitting search results for onPageSearch
   */
  onPageSearchOutput$: Observable<FtSearchResults>;

  /**
   * observable receiving/emmiting insigths term
   */
  private insightsInput$: Subject<FtSearchParams>;

  /**
   * observables emitting insights results
   */
  insightsOutput$: Observable<FtInsightsResults>;

  /**
   * observable receiving/emmiting insigths term
   */
  private insightsCardInput$: Subject<FtSearchParams>;

  /**
   * observables emitting insights results
   */
  insightsCardOutput$: Observable<FtInsightsResults>;

  /**
   * observable receiving/emmiting insigths term
   */
  private insightsTopFourInput$: Subject<FtSearchParams>;

  /**
   * observables emitting insights results
   */
  insightsTopFourOutput$: Observable<FtInsightsResults>;

  /**
   * observable receving and emititng status of modal takeover
   */
  public searchModalStatus$: BehaviorSubject<boolean>;

  /**
   * status of search API - set to true if data is loading
   */
  loading$: Subject<boolean> = new Subject();
  onPageLoading$: Subject<boolean> = new Subject<boolean>();
  private storeArray: Array<string> = [];
  /**
   * version of api read from apiVersion node of elastic search
   * it gives us possibility to map response in different ways when switching environments
   * or upgrading service code
   */
  apiVer$: BehaviorSubject<string> = new BehaviorSubject('?');

  constructor(
    private http: HttpClient,
    private storageService: StorageService,
    private segmentService: SegmentService,
    private globalConfig: GlobalConfigService,
    private router: Router
  ) {
    // search takeover is closed/false by default
    this.searchModalStatus$ = new BehaviorSubject(false);
    this.termInput$ = new Subject<FtSearchParams>();
    this.preSearchInput$ = new Subject();
    this.insightsInput$ = new Subject();
    this.insightsCardInput$ = new Subject();
    this.insightsTopFourInput$ = new Subject();
    this.statusInput$ = new Subject();

    this.onPageSearchInput$ = new Subject<FtSearchParams>();

    /**
     * main search logic
     * - receive input term with config searchParams
     * - set loading$ flag to true
     * - decompose config settings
     * - calculate API parameters
     * - switchMap is to cancel previous request if next is received
     * - sends request to search service
     * - retries 1 time in case of error
     * - share response among multiple subscribers if needed (to avoid repeating operators inside pipe)
     * - set loading flag to false
     */
    this.searchOutput$ = this.termInput$.pipe(
      tap(() => {
        this.loading$.next(true);
      }),
      map((searchParams: FtSearchParams) => {
        const { options, filters } = searchParams;
        const startRecord = options.page * options.counters.funds;
        const httpParams = this.checkParentFirmParam(options);
        return {
          ...options.config,
          httpParams: httpParams
            .set('query', options.term.trim())
            .set('start', startRecord.toString())
            .set('number', options.counters.funds.toString())
            .set('filters', filters),
          // .set('sessionId', generateUUID()), DCE-876
        };
      }),
      switchMap((config) => {
        const req = PageMonitor.startAjaxRequest(
          `search - ${config.httpParams.get('query')}`
        );
        return this.http
          .post<FtSearchResults>(
            config.ftSearchUrl,
            config.httpParams,
            config.httpOptions
          )
          .pipe(
            tap(
              () => {
                PageMonitor.endAjaxRequest(req);
              }, // TODO - add debugging console.log('trying to process response'),
              (err) => {
                logger.error(
                  '[search] Received error from backend request. Please try to reload this page or try later. ' +
                    config.ftSearchUrl +
                    ' [error status]: ' +
                    err.status +
                    ' query: ' +
                    config.httpParams.get('query') +
                    ' sessionId: ' +
                    config.httpParams.get('sessionId') +
                    ' timestamp: ' +
                    new Date()
                );
              }
            ),
            tap((response) => this.apiVer$.next(response.apiVersion)),
            retry(1),
            catchError((err) => {
              logger.error(
                '[search] error while receiving data from backend service after 1 retry: ' +
                  JSON.stringify(err.message) +
                  ' [error status]: ' +
                  err.status +
                  ' sessionId: ' +
                  config.httpParams.get('sessionId') +
                  ' timestamp: ' +
                  new Date()
              );
              return of({
                error: true,
                originalQueryText: config.httpParams.get('query'),
              });
            })
          );
      }),
      share(),
      tap(() => {
        this.loading$.next(false);
      })
    );

    /**
     * preSearchOutput used for receiving data from preSearch API
     */
    this.preSearchOutput$ = this.preSearchInput$.pipe(
      map((searchParams: FtSearchParams) => {
        const { options, filters } = searchParams;
        return {
          ...options.config,
          httpParams: options.config.httpParams
            .set('start', '0')
            .set('number', '8'),
          // .set('sessionId', generateUUID()), DCE-876
        };
      }),
      switchMap((config) => {
        const req = PageMonitor.startAjaxRequest(`presearch`);
        return this.http
          .post<FtPreSearchResults>(
            config.ftPreSearchUrl,
            config.httpParams,
            config.httpOptions
          )
          .pipe(
            tap(
              () => {
                PageMonitor.endAjaxRequest(req);
              },
              (err) => {
                logger.error('[presearch]');
              }
            ),
            retry(1),
            catchError((err) => {
              logger.error('[presearch]');
              return throwError(err);
            })
          );
      }),
      share()
    );

    /**
     * additional process to get and emit status response from Elastic API
     */
    this.statusOutput$ = this.statusInput$.pipe(
      switchMap((config) => {
        return this.http.get<FtSearchStatus>(config.ftSearchStatusUrl).pipe(
          tap(
            () => {}, // console.log('trying to process response'),
            (err) => {
              logger.error(
                '[search] [statusRequest] Received error from backend request. Please try to reload this page or try later. ' +
                  config.ftSearchStatusUrl +
                  ' error: ' +
                  JSON.stringify(err.message)
              );
            }
          ),
          retry(1),
          catchError((err) => {
            logger.error(
              '[search] error while receiving data from backend service: ' +
                JSON.stringify(err.message) +
                ' [error status]: ' +
                err.status
            );
            return throwError(err);
          })
        );
      }),
      share()
    );

    /**
     * this code is providing insigths results #5837
     */
    this.insightsOutput$ = this.insightsInput$.pipe(
      tap(() => {
        // this.loading$.next(true); // TODO - set insightsLoading observable
      }),
      map((searchParams: FtSearchParams) => {
        const { options, filters } = searchParams;
        let currentSegment = '';
        if (options.articleType === ArticleType.PRESS_RELEASE) {
          this.segmentService
            .getCurrentSegmentId$()
            .subscribe((segmentId: SegmentId) => {
              currentSegment = segmentId;
            });
        }
        const httpParams = this.checkParentFirmParam(options);
        return {
          ...options.config,
          httpParams: httpParams
            .set('query', options.term)
            .set('start', options.page.toString())
            .set('number', options.counters.pages.toString())
            .set('articleType', options.articleType)
            .set('filters', filters)
            .set('current', options.current)
            .set('audience', currentSegment),
          // .set('sessionId', generateUUID()), DCE-876
        };
      }),
      switchMap((config) => {
        const req = PageMonitor.startAjaxRequest(
          `insights - ${config.httpParams.get('query')}`
        );
        return this.http
          .post<FtInsightsResults>(
            config.ftInsightsUrl,
            config.httpParams,
            config.httpOptions
          )
          .pipe(
            tap(
              () => {
                PageMonitor.endAjaxRequest(req);
              },
              (err) => {
                logger.error(
                  '[search] [insights] Received error from backend request. Please try to reload this page or try later. ' +
                    config.ftInsightsUrl +
                    ' query: ' +
                    config.httpParams.get('query') +
                    ' articleType: ' +
                    config.httpParams.get('articleType') +
                    ' filters: ' +
                    config.httpParams.get('filters') +
                    ' start: ' +
                    config.httpParams.get('start') +
                    ' number: ' +
                    config.httpParams.get('number') +
                    ' error: ' +
                    JSON.stringify(err.message) +
                    ' sessionId: ' +
                    config.httpParams.get('sessionId') +
                    ' timestamp: ' +
                    new Date()
                );
              }
            ),
            tap((response) => this.apiVer$.next(response.apiVersion)),
            retry(1),
            catchError((err) => {
              logger.error(
                '[search] [insights] error while getting data from backend service (after 1 retry): ' +
                  JSON.stringify(err.message) +
                  ' [error status]: ' +
                  err.status +
                  ' sessionId: ' +
                  config.httpParams.get('sessionId') +
                  ' timestamp: ' +
                  new Date()
              );
              return throwError(err);
            })
          );
      }),
      share(),
      tap(() => {
        // this.loading$.next(false); TODO - replace this with insights loading observable
      })
    );

    /**
     * insightsCardOutput
     */
    this.insightsCardOutput$ = this.insightsCardInput$.pipe(
      tap(() => {
        // this.loading$.next(true); // TODO - set insightsLoading observable
      }),
      map((searchParams: FtSearchParams) => {
        const { options, filters } = searchParams;
        const httpParams = this.checkParentFirmParam(options);
        return {
          ...options.config,
          httpParams: httpParams
            .set('query', options.term)
            .set('start', options.page.toString())
            .set('number', options.counters.pages.toString())
            .set('articleType', options.articleType)
            .set('filters', filters)
            .set('sessionId', generateUUID()),
        };
      }),
      switchMap((config) => {
        logger.debug('trying to get data from backend');
        return this.http
          .post<FtInsightsResults>(
            config.ftInsightsUrl,
            config.httpParams,
            config.httpOptions
          )
          .pipe(
            tap(
              () => {
                logger.debug('trying to process response');
              },
              (err) => {
                logger.error(
                  '[search] [insightsCard] Received error from backend request. Please try to reload this page or try later. ' +
                    config.ftInsightsUrl +
                    ' query: ' +
                    config.httpParams.get('query') +
                    ' article type: ' +
                    config.httpParams.get('articleType') +
                    ' error: ' +
                    JSON.stringify(err.message) +
                    ' sessionId: ' +
                    config.httpParams.get('sessionId') +
                    ' timestamp: ' +
                    new Date()
                );
              }
            ),
            tap((response) => this.apiVer$.next(response.apiVersion)),
            retry(1),
            catchError((err) => {
              logger.error(
                '[search] [insightsCard] error while getting data from backend service: ' +
                  JSON.stringify(err.message) +
                  ' [error status]: ' +
                  err.status +
                  ' sessionId: ' +
                  config.httpParams.get('sessionId') +
                  ' timestamp: ' +
                  new Date()
              );
              return throwError(err);
            })
          );
      }),
      share(),
      tap(() => {
        // this.loading$.next(false); TODO - replace this with insights loading observable
      })
    );

    /**
     * insightsTopFourOutput
     */
    this.insightsTopFourOutput$ = this.insightsTopFourInput$.pipe(
      tap(() => {
        // this.loading$.next(true); // TODO - set insightsLoading observable
      }),
      map((searchParams) => {
        const { options, filters } = searchParams;
        const httpParams = this.checkParentFirmParam(options);
        return {
          ...options.config,
          httpParams: httpParams
            .set('query', options.term)
            .set('start', options.page.toString())
            .set('number', options.counters.pages.toString())
            .set('articleType', options.articleType)
            .set('filters', filters),
          // .set('sessionId', generateUUID()), DCE-876
        };
      }),
      switchMap((config) => {
        logger.debug('trying to get data from backend');
        return this.http
          .post<FtInsightsResults>(
            config.ftInsightsUrl,
            config.httpParams,
            config.httpOptions
          )
          .pipe(
            tap(
              () => {
                logger.debug('trying to process response');
              },
              () => {
                logger.error(
                  '[search] [insightsTopFour] Received error from backend request. Please try to reload this page or try later. ' +
                    config.ftInsightsUrl +
                    ' query: ' +
                    config.httpParams.get('query') +
                    ' article type: ' +
                    config.httpParams.get('articleType') +
                    ' sessionId: ' +
                    config.httpParams.get('sessionId') +
                    ' timestamp: ' +
                    new Date()
                );
              }
            ),
            tap((response) => this.apiVer$.next(response.apiVersion)),
            retry(1),
            catchError((err) => {
              logger.error(
                '[search] [insightsTopFour] error while getting data from backend service: ' +
                  JSON.stringify(err.message) +
                  ' [error status]: ' +
                  err.status +
                  ' sessionId: ' +
                  config.httpParams.get('sessionId') +
                  ' timestamp: ' +
                  new Date()
              );
              return throwError(err);
            })
          );
      }),
      share()
    );

    /**
     * onPageSearchOutput
     */
    this.onPageSearchOutput$ = this.onPageSearchInput$.pipe(
      tap(() => {
        this.onPageLoading$.next(true);
      }),
      map((searchParams: FtSearchParams) => {
        const {
          options,
          filters,
        }: { options: FtSearchOptions; filters } = searchParams;

        const httpParams = this.checkParentFirmParam(options);

        const startRecord = options.page * options.counters.funds;
        return {
          ...options.config,
          httpParams: httpParams
            .set('query', options.term)
            .set('start', startRecord)
            .set('number', options.counters.funds.toString())
            .set('filters', filters)
            .set('collection', options.collection), // funds/literature - passed from on-page search component
          // .set('sessionId', generateUUID()), DCE-876
        };
      }),
      switchMap((config) => {
        logger.debug('trying to get data from backend for onPageSearch');
        return this.http
          .post<FtSearchResults>(
            config.httpParams.get('collection') === SearchCollection.FUNDS
              ? config.ftAutoCompleteUrl
              : config.ftSearchUrl,
            config.httpParams,
            config.httpOptions
          )
          .pipe(
            tap(
              () => {}, // TODO - add debugging console.log('trying to process response'),
              (err) => {
                logger.error(
                  '[search] [onPageSearch] Received error from backend request. Please try to reload this page or try later. ' +
                    config.ftSearchUrl +
                    ' query: ' +
                    config.httpParams.get('query') +
                    ' error: ' +
                    JSON.stringify(err.message) +
                    ' sessionId: ' +
                    config.httpParams.get('sessionId') +
                    ' timestamp: ' +
                    new Date()
                );
              }
            ),
            retry(1),
            catchError((err) => {
              logger.error(
                '[search] [onPageSearch] error while getting data from backend service for onPageSearch: ' +
                  JSON.stringify(err.message) +
                  ' [error status]: ' +
                  err.status +
                  ' sessionId: ' +
                  config.httpParams.get('sessionId') +
                  ' timestamp: ' +
                  new Date()
              );
              return of({
                error: true,
                originalQueryText: config.httpParams.get('query'),
              });
            })
          );
      }),
      share(),
      tap(() => {
        this.loading$.next(false);
      })
    );
  }

  /**
   * to check if parent firm id will be passed in the params for search
   */
  private checkParentFirmParam(options: FtSearchOptions): HttpParams {
    if (
      (options.collection === SearchCollection.FUNDS ||
        options.collection === SearchCollection.PAGES ||
        options.articleType === SearchCollection.INSIGHT) &&
      options?.config?.httpParams?.has('parentFirmGlobalId')
    ) {
      return options.config.httpParams.delete('parentFirmGlobalId');
    } else {
      return options.config.httpParams;
    }
  }

  /**
   * receives status of search API
   */
  getStatus(config: { ftSearchStatusUrl: string }) {
    this.statusInput$.next(config);
  }

  /**
   * gets insights using search term
   * @param config Filters are set in confg.searchParams
   */
  getInsights(searchParams: FtSearchParams): void {
    this.insightsInput$.next(searchParams);
  }

  /**
   * gets insights using search term
   * @param config Filters are set in confg.searchParams
   */
  getInsightsCard(searchParams: FtSearchParams): void {
    this.insightsCardInput$.next(searchParams);
  }

  /**
   * gets insights using search term
   * @param config Filters are set in confg.searchParams
   */
  getInsightsTopFour(searchParams: FtSearchParams): void {
    this.insightsTopFourInput$.next(searchParams);
  }

  /**
   * triggers search with config params
   */
  search(searchParams: FtSearchParams): void {
    // TODO consider implementing http interceptor for headers, error handling and cache
    // https://www.tektutorialshub.com/angular/angular-http-error-handling/#catch-error-globally-using-http-interceptor

    // this just triggers 'next' for search
    this.termInput$.next(searchParams);
  }

  /**
   * triggers preSearch with config params
   */
  preSearch(searchParams: FtSearchParams): void {
    this.preSearchInput$.next(searchParams);
  }

  /**
   * toggles modal takeover
   * UDS-1855 - Additional functionality to route to separate search page instead of open modal.
   * It is depend on search configuration.
   */
  public searchModalToggle(visible: boolean): void {
    logger.debug('FtSearchService requested modal: ' + visible);
    const searchConfig = this.globalConfig.getSearchConfig();
    if (searchConfig?.enableSearchPage) {
      const searchPageUrl = searchConfig?.searchPageUrl || '/search';
      const queryParams = this.router.parseUrl(this.router.url).queryParams;
      this.router.navigate([searchPageUrl], { queryParams });
      return;
    }
    this.searchModalStatus$.next(visible);
  }

  /**
   * findAFund trigger
   * @param searchParams search parameters
   */
  findAFund(searchParams: FtSearchParams): void {
    this.onPageSearchInput$.next(searchParams);
  }

  /*
to store recent search items in local storage for length to 5
*/
  storeRecentSearch(term: string, recentStorageCount: number) {
    if (this.storeArray.includes(term)) {
      return;
    }
    if (this.storeArray.length > recentStorageCount - 1) {
      this.storeArray.shift();
    }
    this.storeArray.push(term);
    this.storageService.store(
      SEARCH_STORAGE_NAME_KEY,
      JSON.stringify(this.storeArray),
      false
    );
  }
  clearRecentSearch(): void {
    this.storeArray = [];
    this.storageService.remove(SEARCH_STORAGE_NAME_KEY, false);
  }
}
