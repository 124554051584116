import { Injectable } from '@angular/core';
import { IUserProfile } from '@services';

// TODO: should this be renamed just "InteractiveWidgetService" ?
@Injectable({
  providedIn: 'root',
})
export class InteractiveWidgetServiceService {
  constructor() {
    // Constructor placeholder
  }

  /**
   * Get Is Firm Allowed for display
   * @param firmNames - Array of strings (firms excluded from display)
   * @param userProfile - User Profile date object
   */
  public getIsFirmAllowed(
    firmNames: Array<string>,
    userProfile: IUserProfile
  ): boolean {
    if (firmNames?.length > 0) {
      return !firmNames.some((firm: string) => {
        const firmName = this.unifyFirmName(firm);
        const profileFirm = this.unifyFirmName(
          userProfile.profileInfo?.firm?.toUpperCase()
        );
        // FirmId in profile is a string, however it has always numeric value.
        // Parsing it to int can avoid issues with firm names used in previous functionality.
        const firmId =
          typeof userProfile.firm?.parentFirmId === 'string'
            ? parseInt(userProfile.firm.parentFirmId, 10)
            : null;
        return (
          firmId === parseInt(firm, 10) ||
          profileFirm?.includes(firmName) ||
          userProfile.profileInfo?.email?.toUpperCase().includes(firmName)
        );
      });
    }
    return true;
  }

  /**
   * Unify FirmName - removes space and dashes, convert to uppercase.
   * @param firmName - string
   */
  private unifyFirmName(firmName): string {
    if (!firmName) {
      return;
    }
    return firmName.replace(/-/g, '').replace(/ /g, '').toUpperCase();
  }
}
