import { Component, HostListener, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  AdditionalLegacyLink,
  SignInComponentFieldsInfo,
  SignInEvent,
} from '@frk/eds-components';
import {
  AppStateService,
  GlobalConfigService,
  IUserProfile,
  ProfileService,
  SignInService,
  SiteConfigService,
} from '@services';
import { AbstractBaseComponent } from '@shared/abstract-base/abstract-base.component';
import { Logger } from '@utils/logger';
import {
  AnalyticsLogin,
  SignInInterface,
  Skin,
  Skins,
} from './sign-in.interface';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

const logger = Logger.getLogger('SignInComponent');

@Component({
  selector: 'ft-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent extends AbstractBaseComponent implements OnInit {
  public signIn: SignInInterface;
  public signInForm: FormGroup;
  public isSubmitButtonDisabled = false;
  public profile: IUserProfile;
  public hasSignIn = true;
  public hasSignOut = true;
  // private loginOption = 'none'; // this doesn't seem to be used anymore
  public isLegacy = false;
  public showLegacyContent = true;
  public signInComponentContent: SignInComponentFieldsInfo;
  private skins: Skins = {
    basic: 'Basic',
    basicTwoCols: 'Basic 2 Columns',
    twoCols: 'Two Columns',
    threeCols: 'Three Columns',
    basicNoSpacing: 'Basic-No-Spacing',
  };
  public skin: Skin;

  public leftPreTitle = '';
  public leftTitle = '';
  public leftContent = '';

  public signInFormAction = '';
  public signInHiddenInputs = [];
  private unsubscribe$: Subject<void> = new Subject<void>();

  // Page and Component inherited from AbstractBaseComponent
  @Input() inputParameters?: SignInInterface;

  constructor(
    private formBuilder: FormBuilder,
    private profileService: ProfileService,
    private globalConfigService: GlobalConfigService,
    private signInService: SignInService,
    private appStateService: AppStateService,
    private siteConfigService: SiteConfigService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.signInForm = this.signInService.signInFormInit(this.formBuilder);

    // sets labels etc from component model data
    this.setSignIn();

    // TODO: should this be using async getUserProfile$() ?
    this.profile = this.profileService.getUserProfile();
    this.setSignInComponentContent(this.signIn);

    this.signInService
      .getFastTrackRegistration$(this.signIn)
      ?.pipe(takeUntil(this.unsubscribe$))
      .subscribe((signIn: SignInInterface) => {
        this.setSignInComponentContent(signIn);
      });

    if (this.appStateService.isSimplyLoginMethod()) {
      this.signInFormAction = this.signInService.getLoginUrl();
      this.signInHiddenInputs = this.signInService.getHiddenInputs();
    }

    this.setLeftContentParams(this.signIn);
  }

  /**
   * Function to Set sign in labels.
   */
  public setSignIn(): void {
    try {
      // Try to get SignIn from component model
      const { SignIn } = this.component?.getModels();
      this.signIn = SignIn;
    } catch (e) {
      // If data not present, return default settings.
      // This can happen when sign-in form is embedded in another component like Attribution or Commentary
      // Default data is based on what old fixture json would have returned
      // Data could be pass in component inputParameters.
      this.signIn = {
        legacy: this.inputParameters?.legacy,
        showLegacyContent: this.inputParameters?.showLegacyContent,
        signOut: this.inputParameters?.signOut,
        skin: this.inputParameters?.skin,
      };
      if (this.inputParameters?.subTitle) {
        this.signIn.subTitle = this.inputParameters?.subTitle;
      }
      if (this.inputParameters?.signInBodyContent) {
        this.signIn.signInBodyContent = this.inputParameters?.signInBodyContent;
      }
      if (this.inputParameters?.signInLegacyContent) {
        this.signIn.signInLegacyContent = this.inputParameters?.signInLegacyContent;
      }
      if (this.inputParameters?.signInTitle) {
        this.signIn.signInTitle = this.inputParameters.signInTitle;
      }
    }
    this.skin = this.getSkin();
    this.isLegacy = this.getLegacy();
    this.hasSignOut = this.getSignOut();
  }

  @HostListener('click', ['$event'])
  onClick(event: Event): void {
    this.signInService.trackLogin(event);
  }

  /**
   * Get skin for component
   */
  private getSkin(): Skin {
    if (this.inputParameters?.skin) {
      return this.selectSkin(this.inputParameters.skin);
    }
    return this.selectSkin(this.signIn.skin);
  }

  /**
   * Get LM legacy for component
   */
  private getLegacy(): boolean {
    // Check Site configuration first
    if (
      this.globalConfigService.config.siteConfiguration?.site
        ?.authenticationLegacy
    ) {
      // If there is component configuration to not display legacy use it as priority.
      if (this.signIn.legacy === false) {
        return false;
      }
      return this.globalConfigService.config.siteConfiguration.site
        .authenticationLegacy;
    }
    if (typeof this.inputParameters?.legacy !== 'undefined') {
      return this.inputParameters.legacy;
    }
    return this.signIn.legacy;
  }

  /**
   * Get signOut button for component
   */
  private getSignOut(): boolean {
    if (typeof this.inputParameters?.signOut !== 'undefined') {
      return this.inputParameters.signOut;
    }
    return this.signIn.signOut;
  }

  public onSubmit(signIn: SignInEvent): void {
    logger.debug('SignInEvent', signIn);
    let canSubmit = true;
    if (!this.siteConfigService.hideLoginForm()) {
      const isSignInFormValid = this.signInService.isSignInFormValid(
        signIn,
        this.signInForm,
        this.signInComponentContent
      );
      canSubmit = isSignInFormValid.canSubmit;
      this.signInForm = isSignInFormValid.signInForm;
      this.signInComponentContent = isSignInFormValid.signInComponentContent;
      // Commented now according to NGC-8586
      // this.signInForm.value.rememberMe = signIn.rememberMe ? REMEMBER_ME_YES : REMEMBER_ME_NO;
    }
    this.isSubmitButtonDisabled = canSubmit;
    if (canSubmit) {
      const submitObject: AnalyticsLogin = {
        signInForm: this.signInForm,
        analyticsKey: 'Banner',
      };
      this.signInService.onSubmit(submitObject);
    }
  }

  /**
   * Trigger legacy button click
   * @param linkEvent - AdditionalLegacyLink
   */
  public legacyBtnclick(linkEvent: AdditionalLegacyLink): void {
    this.signInService.openLegacyLink(linkEvent);
  }

  private checkForm(signIn: SignInEvent): boolean {
    let canSubmit = false;
    this.signInForm.value.userId = signIn.userName;
    this.signInForm.value.password = signIn.password;
    // Commented now according to NGC-8586
    // this.signInForm.value.rememberMe = signIn.rememberMe ? REMEMBER_ME_YES : REMEMBER_ME_NO;
    if (!signIn.userName && !signIn.password) {
      this.signInComponentContent.userName.invalid = true;
      this.signInComponentContent.password.invalid = true;
    } else if (!signIn.userName && signIn.password) {
      this.signInComponentContent.userName.invalid = true;
      this.signInComponentContent.password.invalid = false;
    } else if (signIn.userName && !signIn.password) {
      this.signInComponentContent.userName.invalid = false;
      this.signInComponentContent.password.invalid = true;
    } else {
      this.signInComponentContent.userName.invalid = false;
      this.signInComponentContent.password.invalid = false;
      this.isSubmitButtonDisabled = true;
      canSubmit = true;
    }
    return canSubmit;
  }

  public signOut(): void {
    this.signInService.signOut();
  }

  /**
   * redirect to auth0 signup screen
   */
  public fastTrackRegistration() {
    this.signInService.submitAuth0('signup');
  }

  /**
   * Set content form component params
   * @param componentParams - parameters form component
   */
  private setLeftContentParams(componentParams: SignInInterface): void {
    logger.debug('setLeftContentParams()', componentParams);
    if (componentParams.signInLeftPreTitle !== '') {
      this.leftPreTitle = componentParams.signInLeftPreTitle;
    }
    if (componentParams.signInLeftTitle !== '') {
      this.leftTitle = componentParams.signInLeftTitle;
    }
    if (componentParams.signInLeftContent !== '') {
      this.leftContent = componentParams.signInLeftContent;
    }
    // TODO: Check possibility of using JCR document. This may impact embedding in other component functionality.
    // if (componentParams.document !== '') {
    //   this.leftContent = componentParams.document;
    // }
  }

  private selectSkin(componentSkin: string): Skin {
    switch (componentSkin) {
      case this.skins.basic:
        return {
          name: 'basic',
          offsets: {},
          columnNumbers: {},
          edsSpacing: 'md',
        };
      case this.skins.basicTwoCols:
        return {
          name: 'basicTwoCols',
          offsets: {},
          columnNumbers: { s: 4, m: 10, l: 10 },
          edsSpacing: 'md',
        };
      case this.skins.twoCols:
        return {
          name: 'twoCols',
          offsets: { l: 1 },
          columnNumbers: { s: 4, m: 4, l: 5 },
          addSectionColNumbers: { s: 4, m: 4, l: 4 },
          edsSpacing: 'xl',
        };
      case this.skins.threeCols:
        return {
          name: 'threeCols',
          offsets: {},
          addSectionColNumbers: {},
          columnNumbers: { s: 4, m: 4, l: 7 },
          edsSpacing: 'xl',
        };
      case this.skins.basicNoSpacing:
        return {
          name: 'basicNoSpacing',
          offsets: {},
          columnNumbers: {},
          edsSpacing: 'none',
        };
      default:
        return {
          name: 'basic',
          offsets: {},
          columnNumbers: {},
          edsSpacing: 'none',
        };
    }
  }

  private setSignInComponentContent(signIn: SignInInterface): void {
    this.signInComponentContent = this.signInService.getSignInComponentFieldsInfo(
      signIn
    );
    // UDS-1800 - For up-lift cleanup requirement additionalLegacy link menu is deprecated and should not be displayed.
    if (this.signInComponentContent?.additionalLegacy) {
      this.signInComponentContent.additionalLegacy = [];
    }
    logger.debug('signInComponentContent', this.signInComponentContent);
  }
}
