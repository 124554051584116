import { Injectable } from '@angular/core';
import { WidenDocumentJson } from '@types';
import { Logger } from '@utils/logger';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppStateService } from './app-state.service';
import { SiteConfigService } from './site-config.service';

/**
 * Logger
 */
const logger = Logger.getLogger('WidenService');

export interface WidenFirmLogo {
  firmRole: string;
  widenFirmLogoUrl?: string;
  widenFirmMobileLogoUrl?: string;
  mobilLogoForHandheld?: boolean;
  isLogoNotClicable?: boolean;
  customLogoLink?: string;
}

@Injectable({
  providedIn: 'root',
})
export class WidenService {
  /**
   * Orange Icons
   */
  ORG_ASSETS_PATH =
    '/binaries/content/gallery/global/franklin-templeton/common/icons/org-icons/';

  /**
   * Blue Icons
   */
  BLUE_ASSETS_PATH =
    '/binaries/content/gallery/global/franklin-templeton/common/icons/blue-icons/';

  /**
   * Green Icons
   */
  GRN_ASSETS_PATH =
    '/binaries/content/gallery/global/franklin-templeton/common/icons/grn-icons/';

  /**
   * Misc Assets
   */
  MISC_ASSETS_PATH =
    '/binaries/content/gallery/global/franklin-templeton/common/icons/misc/';

  /**
   * Collection of image setting for components
   */
  IMAGE_SETTINGS = {
    'eds-card-article': {
      height: 600,
      width: 800,
      keep: 'c',
      crop: true,
    },
    'eds-banner-article-insights': {
      height: 622,
      width: 1400,
      keep: 'c',
      crop: true,
    },
  };
  private firmLogos$: BehaviorSubject<WidenFirmLogo[]> = new BehaviorSubject<
    WidenFirmLogo[]
  >([]);

  private widenBaseUrl: string;

  /**
   * Empty contructor
   */
  constructor(
    private appStateService: AppStateService,
    private siteConfigService: SiteConfigService
  ) {
    this.widenBaseUrl = this.appStateService?.getWidenBaseUrl();
  }

  /**
   * Parse widen asset json and read url and calls transformWidenPath only if the site config have the WidenURLMask
   * @param widenAsset - Widen asset JSON string
   * @returns - URL string
   */
  public getWidenAssetUrl(widenAsset: string): string {
    const widenJson = widenAsset ? JSON.parse(widenAsset) : '';
    logger.debug(widenJson);
    let url = widenJson.url;
    logger.debug(url);
    if (this.siteConfigService.common?.widenURLMask && url !== '') {
      url = this.transformWidenPath(url);
    }
    return this.convertIconPath(url);
  }

  /**
   * Serve webp image to supported browsers
   * @param imageRef - Image Reference
   * @param originalVariant - Original
   * @param replacedVariant - Replacement
   * @param component - Component name (optional)
   */
  public getWidenImageVariantUrl(
    imageRef: string,
    originalVariant: string,
    replacedVariant: string,
    component?: string
  ): string {
    logger.debug('Get image: ' + imageRef);

    if (imageRef?.endsWith('.svg')) {
      // Proxy SVGs through Literature API
      const replacedImageRef = imageRef.replace(
        this.appStateService.getWidenUrl(),
        this.appStateService.getWidenProxyUrl()
      );
      logger.debug('replaced: ' + replacedImageRef);
      return replacedImageRef;
    } else {
      const isSafari = /^((?!chrome|android).)*safari/i.test(
        navigator.userAgent
      );
      if (imageRef && !isSafari) {
        originalVariant = '/' + originalVariant + '/';
        replacedVariant = '/' + replacedVariant + '/';
        const replacedImageRef = imageRef.replace(
          originalVariant,
          replacedVariant
        );
        return replacedImageRef + this.getImageSettings(component);
      }
    }

    return imageRef + this.getImageSettings(component);
  }

  /**
   * If the assets is an SVG we must ensure it
   * is a relative path.
   * TODO: Change to regex groups
   */
  private convertIconPath(url: string): string {
    // Proxy SVGs through Literature API
    logger.debug('convertIconPath: ' + url);
    if (url?.endsWith('.svg')) {
      url = url.replace(
        this.appStateService.getWidenUrl(),
        this.appStateService.getWidenProxyUrl()
      );
    }
    logger.debug('converted: ' + url);
    return url;
  }

  /**
   * If the component name available then
   * map the image setting from object and
   * retrun as a string
   * @param component - Name of the component
   */
  private getImageSettings(component: string): string {
    if (component) {
      let imageParameters = '?';
      Object.entries(this.IMAGE_SETTINGS[component]).forEach(([key, value]) => {
        imageParameters += key + '=' + value + '&';
      });
      return imageParameters;
    }
    return '';
  }

  /**
   * Get thumbnail url for embed_id
   * @param embedId - the asset embed id
   */
  public getThumbnailUrl(embedId: string): string {
    // https://franklintempletonprod.widen.net/content/alfdif5usz?height=160
    return (
      this.appStateService.getWidenUrl() + '/content/' + embedId + '?height=200'
    );
  }

  /**
   * Get Widen Asset Audience
   * @param widenAsset - Widen asset JSON string
   * @returns - Audience string
   */
  public getWidenAssetAudience(widenAsset: string): string {
    const widenJson = widenAsset ? JSON.parse(widenAsset) : '';
    logger.debug(widenJson);
    const audience = widenJson.audience;
    logger.debug(audience);
    return this.convertIconPath(audience);
  }

  /**
   * Get Lit Code
   * @param widenAsset - Widen asset JSON string
   * @returns - LLit code string
   */
  public getLitCode(widenAsset: string): string {
    const widenJson = widenAsset ? JSON.parse(widenAsset) : '';
    logger.debug(widenJson);
    const litCode = widenJson.litCode;
    logger.debug(litCode);
    return this.convertIconPath(litCode);
  }

  /**
   * Get Is Client Use
   * @param widenAsset - Widen asset JSON string
   * @returns - Client use string
   */
  public getIsClientUse(widenAsset: string): string {
    const widenJson = widenAsset ? JSON.parse(widenAsset) : '';
    logger.debug(widenJson);
    const isClientUse = widenJson.isClientUse;
    logger.debug(isClientUse);
    return this.convertIconPath(isClientUse);
  }

  /**
   * Get Asset File Extension from URL
   * @param widenAsset - String wilh widen asset JSON
   * @returns - file extension string
   */
  public getAssetFileExtension(widenAsset: string): string | null {
    if (!widenAsset) {
      return null;
    }
    const widenJson: WidenDocumentJson = JSON.parse(
      widenAsset
    ) as WidenDocumentJson;
    const parts: string[] = widenJson.url?.split('.');
    return parts?.pop()?.toLowerCase() || null;
  }

  /**
   * Is Video Asset
   * @param widenAsset - String wilh widen asset JSON
   * @returns - true if file extension is a video type
   */
  public isVideoAsset(widenAsset: string): boolean {
    const promoType = this.getAssetFileExtension(widenAsset);
    const videoTypes = ['mp4', 'webm', 'ogg'];
    return videoTypes.includes(promoType);
  }

  /**
   * Set Firm Logos from Widen
   * @param firmLogos - WidenFirmLogo array
   */
  public setFirmLogosFromWiden(firmLogos: WidenFirmLogo[]): void {
    this.firmLogos$.next(firmLogos);
  }

  /**
   * Get Firm Logos from Widen
   * @returns - Observable of WidenFirmLogos array
   */
  public getFirmLogosFromWiden$(): Observable<WidenFirmLogo[]> {
    return this.firmLogos$.asObservable();
  }

  /**
   * transform the widen path with Mask URL if  it start with widenBaseUrl and widenUrlMask should not be empty string
   * @param widenAsset - URL string
   * @returns - URL string
   */
  public transformWidenPath(value: string): string {
    if (!value) {
      return value;
    } // Early return for null or undefined

    const widenPrefix = this.widenBaseUrl + '/content/';

    if (value.startsWith(widenPrefix)) {
      const widenUrlMask = this.siteConfigService.getWidenUrlMask();
      const urlPattern = /^https?:\/\/.+$/; // Regex to validate a proper URL

      if (widenUrlMask && urlPattern.test(widenUrlMask)) {
        const transformedUrl = value.replace(this.widenBaseUrl, widenUrlMask);
        logger.debug('Transformed Widen URL:', transformedUrl);
        return transformedUrl;
      } else {
        logger.warn('Invalid or empty Widen URL Mask:', widenUrlMask);
      }
    }

    return value;
  }
}
