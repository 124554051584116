import {
  AfterViewInit,
  Input,
  OnInit,
  OnDestroy,
  Component,
  ViewChild,
  ElementRef,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Inject,
  PLATFORM_ID,
  Renderer2,
  Output,
  EventEmitter,
  HostListener,
} from '@angular/core';
import { Location, isPlatformBrowser } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import {
  Observable,
  Subject,
  of,
  Subscription,
  fromEvent,
  ObservedValueOf,
} from 'rxjs';
import {
  takeUntil,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  startWith,
  tap,
  delay,
} from 'rxjs/operators';
import {
  ActivatedRoute,
  NavigationStart,
  Params,
  Router,
} from '@angular/router';
import { Logger } from '@utils/logger';

import {
  Aggregations,
  ExactMatch,
  FtSearchResponse,
  FtPreSearchResultsItem,
  FtSearchOptions,
  FtSearchStatus,
  ToggleState,
} from './interfaces/search.interface';

import { FtSearchService } from './services/ftsearch.service';
import { FiltersService } from './services/filters.service';
import { ConfigService } from './services/config.service';
import { GlobalConfigService } from '@services/global-config-service';
import { NgxSpinnerService } from 'ngx-spinner';

import { CommonConfig, SiteSearchConfig } from '@types';

import {
  AnalyticsService,
  SearchServiceToken,
  ResponsiveService,
  FrkBreakpoint,
} from '@frk/eds-components';
import isEmpty from 'lodash/isEmpty';
import {
  trigger,
  state,
  style,
  animate,
  transition,
  // ...
} from '@angular/animations';
import { removeParam } from '@utils/link-utils';
import { TranslateService } from '@shared/translate/translate.service';
import { checkLink } from '@utils/link-utils';
import { WINDOW } from '@ng-web-apis/common';

const logger = Logger.getLogger('SearchComponent');
const ANALYTICS_SEARCH = 'search';
const ANALYTICS_SEARCH_FAILED = 'search_failed';

@Component({
  selector: 'ft-search',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  animations: [
    trigger('openClose', [
      // ...
      state(
        'open',
        style({
          opacity: 0.99,
        })
      ),
      state(
        'closed',
        style({
          opacity: 0,
        })
      ),
      transition('closed => open', [animate('0.8s 0.1s')]),
      transition('open => closed', [animate('0.8s 0.5s ease-out')]),
    ]),
  ],
})
export class SearchComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() headerStyle: string;

  @Input() searchPlaceholderText: string;

  /**
   * Site config
   */
  siteGeneral: CommonConfig;
  searchConfig: SiteSearchConfig;
  clearRecent = false;
  /**
   * main search flag enabling/disabling search
   */
  hasSearch = false;
  hasBasicHeader = false;

  /**
   * enables additional logging in AppInsight - for example - not found and not found followed by found
   */
  extendedLoggingEnabled = false;
  enablePreSearchModule = false;
  searchDebounceTime = 500;
  minTermLength = 2;

  subscription: Subscription = new Subscription();
  componentDestroyed$: Subject<boolean> = new Subject();
  @Output() isSearchActive = new EventEmitter<boolean>();
  @ViewChild('searchBarInput') searchBarInput: ElementRef;

  /**
   * modal show and animate flags
   */
  showModal = false;
  animateModal = false;

  /**
   * search default values TODO - verify this after implementing routing 'back'
   */
  originalQueryText: string;
  updatedQueryText: string;

  // set of flags detecting if we need to update query string or perform search after query string changed
  queryStringUpdated: boolean;
  queryFromRouteChange: boolean;
  navigationStart: boolean;
  popstateChanged: boolean;

  activeToggle: ToggleState = 'full';
  selectedToggle: ToggleState = null;
  displayedPage = 0;
  searchTerm = '';
  results: FtSearchResponse[] = [];
  aggregations = {};
  exactMatch: ExactMatch = null;

  loading = false;
  notFound = false;
  error = false;

  // this is to track not found events followed by found
  recentNotFound: { term: string; timestamp: number };

  // TODO move to config
  options: FtSearchOptions = {
    page: 0,
    term: '',
    dataSource: 'dev',
    config: {
      ftSearchUrl: '',
      ftPreSearchUrl: '',
      ftInsightsUrl: '',
      httpParams: new HttpParams(),
      httpOptions: {},
    },
    // TODO verify if we use it and move to config
    counters: {
      funds: 8,
      literature: 8,
      pages: 8,
    },
  };

  // TODO move type to config
  statusJson: FtSearchStatus = {
    hosts: [
      {
        type: '',
        status: '',
      },
    ],
    config: {},
  };

  // TODO move to config
  exactMatchTypes = ['fund', 'fund_manager', 'thematic_topic'];

  apiVer = '';
  foundMessage = '';

  /**
   * angular device detection
   */
  isHandheld$: Observable<boolean>;
  getBreakpointObs$: Observable<FrkBreakpoint>;
  isHandheld: boolean;

  showingResultsForLabel: string;

  public quickLinks: FtPreSearchResultsItem[];
  @Input() recentStorageCount = 8;

  constructor(
    private activatedRoute: ActivatedRoute,
    private analyticsService: AnalyticsService,
    private filters: FiltersService,
    private configService: ConfigService,
    private cd: ChangeDetectorRef,
    private responsiveService: ResponsiveService,
    private pageConfigService: GlobalConfigService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private location: Location,
    private renderer2: Renderer2,
    private translateService: TranslateService,
    @Inject(PLATFORM_ID) platform,
    @Inject(SearchServiceToken) private searchService: FtSearchService,
    @Inject(WINDOW) readonly windowRef: Window
  ) {
    this.siteGeneral = this.pageConfigService.getSiteGeneral();
    this.hasSearch = this?.siteGeneral?.searchHeader || false;

    this.searchConfig = this.pageConfigService.getSearchConfig();
    this.extendedLoggingEnabled =
      this?.searchConfig?.enableExtendedSearchLogs || false;

    this.enablePreSearchModule =
      this?.searchConfig?.enablePreSearchModule || false;

    this.searchDebounceTime = this?.searchConfig?.searchDebounceTime || 500;
    this.minTermLength = this?.searchConfig?.minTermLength
      ? this.searchConfig.minTermLength - 1
      : 2;

    if (!this.hasSearch) {
      return null;
    }

    this.hasBasicHeader = this.headerStyle?.startsWith('basic');

    // triggers search refresh in case network is back (and search modal is opened)
    // this works only after user had error displayed on search results (tried to search when error was down)
    // in the future we can extract this to separate service
    if (isPlatformBrowser(platform)) {
      fromEvent(windowRef, 'online')
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe(() => {
          if (
            this.searchService.searchModalStatus$.value &&
            this.originalQueryText &&
            this.error
          ) {
            this.onTerm(this.originalQueryText);
          }
        });
    }

    // subscribes to loading & status output
    this.activateSubscriptions();

    /**
     * sets value for preSearch on empty search screen
     */
    this.searchService.preSearchOutput$
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((response) => {
        this.quickLinks =
          response?.results[0].response?.hits?.hits[0]?._source?.quickLinks;
        this.cd.detectChanges();
      });

    // main code responsible for processing searchOutput
    this.searchService.searchOutput$
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        ({
          results,
          parametrics,
          fundInstant,
          instantDisplays,
          originalQueryText,
          updatedQueryText,
          totalResults,
          error,
        }) => {
          // set original search query param, also in case of error response
          this.originalQueryText = originalQueryText
            ? originalQueryText.trim()
            : '';

          // error handling without unsubscribing from observable
          if (error) {
            this.errorHandle();
            return;
          }
          // toggle behavior and not found message is displayed only if there are no active filters
          const filtersSelected = this.filters.countFilters() !== 0;

          // set search term for messages
          this.updatedQueryText = updatedQueryText.trim();

          // update query string in a browser
          // only if this query was not set by bpdated url
          if (!this.queryFromRouteChange && this.options.page === 0) {
            this.updateQueryString(this.updatedQueryText);
          }
          this.queryFromRouteChange = false;

          // this check is performed if no filters are selected
          if (!this.checkResults(results) && !filtersSelected) {
            this.notFound = true;

            // we register this not found with timestamp
            this.recentNotFound = {
              term: this.originalQueryText,
              timestamp: Date.now(),
            };

            if (this.extendedLoggingEnabled) {
              logger.debug(
                '[search] [notFound] term:' + this.originalQueryText
              );
            }
            // analytics tracking
            this.analyticsService.trackEvent({
              event: ANALYTICS_SEARCH_FAILED,
              detailed_event: 'Search Failed',
              search_category: 'failed',
              original_search_term: this.originalQueryText,
              search_term: this.updatedQueryText,
              search_num_results: '0',
              event_data: {
                search_category: 'failed',
                search_num_results: '0',
                search_term: this.updatedQueryText,
              },
            });

            return;
          }

          // append results if quering for next pages
          if (this.options.page > 0) {
            this.results = this.appendResults(results);
            this.displayedPage = this.options.page;
          }

          // set results if this is first time query for this term
          if (this.options.page === 0) {
            this.results = results;
            this.displayedPage = 0;
          }

          // process filters
          this.aggregations = this.processAggregations(
            parametrics.results?.aggregations
          );

          // set exact match if available and not handheld
          // we can also disable here exactMatch for countries which don't need this
          // however proper way of doing this will be in Elastic config
          this.exactMatch = this.isHandheld
            ? null
            : this.getExactMatchData(
                originalQueryText,
                fundInstant,
                instantDisplays
              );

          // TODO - this may be refactored
          // we don't need to set to exact match if filtering was done.
          // we also don't need this when user manually swithed to 'all' tab
          this.activeToggle = 'full';
          if (
            this.selectedToggle !== 'full' &&
            this.exactMatch &&
            this.exactMatch.type !== '' &&
            this.options.page === 0 &&
            !filtersSelected
          ) {
            this.activeToggle = 'exact';
          }

          this.searchService.loading$.next(false);
          this.foundMessage = this.getFoundMessage();
          if (originalQueryText) {
            this.isSearchActive.emit(true);
          }

          this.notFound = false;
          this.error = false;
          this.cd.markForCheck();

          // TODO save search into localStorage (async)
          // read existing array for key 'recent_searches', if not exist create one
          // we save 5 terms + make sure it is unique
          // check this.storageService methods

          // ANALYTICS code
          const analyticsCategory = this.getAnalyticsSearchCategory(
            this.exactMatch?.type
          );
          // we want to track only first search term, not search with filters
          if (!filtersSelected && this.options.page === 0) {
            this.analyticsService.trackEvent({
              event: ANALYTICS_SEARCH,
              detailed_event: 'Onsite Search Performed',
              search_category: analyticsCategory,
              search_term: this.updatedQueryText,
              original_search_term: this.originalQueryText,
              search_num_results: totalResults, // TODO - this is probably not correct value from search API - TBD! backend change
              event_data: {
                search_category: analyticsCategory,
                search_num_results: totalResults,
                search_term: this.updatedQueryText,
              },
            });
          }

          // trying to identify if found is followed by notfound in 2000 ms
          // this is to improve debounce time
          if (
            this.extendedLoggingEnabled &&
            this.recentNotFound &&
            Date.now() - this.recentNotFound.timestamp < 2000
          ) {
            if (this.originalQueryText.startsWith(this.recentNotFound.term)) {
              logger.debug(
                '[search] [notFound] [found] term:' +
                  this.recentNotFound.term +
                  'followed by: ' +
                  this.originalQueryText
              );
              this.recentNotFound = null; // reser recentNotFound flag
            }
          }
        },
        () => {
          // error handle with unsubscription
          this.errorHandle();
        }
      );

    // TODO verify and remove if not needed
    // It verifies status of backend search
    this.searchService.statusOutput$
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        (status) => {
          this.statusJson = status;
          this.cd.markForCheck();
        },
        (error) => {
          this.statusJson = {
            hosts: [
              {
                type: '',
                status: 'network down',
              },
            ],
            config: {},
            error: 'network error',
          };
          this.cd.markForCheck();
        }
      );

    // Intercepts router navigation to close search before navigating
    // it also adds query string to url for back action.
    this.router.events
      .pipe(
        takeUntil(this.componentDestroyed$),
        // TODO we may need to filter only activities/clicks from search component ? if possible
        filter((event) => event instanceof NavigationStart),
        tap((event: NavigationStart) => {
          if (
            // TODO - this is workaround
            // I don't want to modify all logic and filters due to many dependencies
            // this is just one fix for #documents link from exact-match details
            // the main issue which prevents refactoring is this.navigationStart = true; in line 456
            // we probably need to refactor code and detect navigationStart in proper way
            event.url.split('#')[1] === 'documents' &&
            this.searchService.searchModalStatus$.value
          ) {
            this.closeSearch();
          }
        }),
        // removes # fragment so we can ignore in-page links
        map((event: NavigationStart): string => event.url.split('#')[0]),
        // this is to recognize route after user types first search term and there is no query string in url
        startWith(this.router.routerState?.snapshot.url.split('#')[0]),
        // ignores parameter 'query' which is changed from search component
        // we don't need to load new page when query string is changing when search term is changed
        map((url: string) => (url ? this.removeParam(url, 'query') : '')),
        map((url: string) => (url ? this.removeParam(url, 'queryType') : '')),
        // distinctUntilChanged() means this only fires for route changes - not fragment changes
        // TODO filter #changes
        distinctUntilChanged()
      )
      .subscribe((url) => {
        // sets flag informing that url has changed and search is not require before component reloads.
        this.navigationStart = true;
        // hide search takeover if opened
        if (this.searchService.searchModalStatus$.value) {
          this.closeSearch();
        }
      });
  }

  // error handling for searchOutput
  private errorHandle() {
    this.searchService.loading$.next(false);
    this.notFound = false;
    this.error = true;
    this.cd.markForCheck();
    // analytics - failed search TBD?
    // TODO TEST if we can get additional data
    this.analyticsService.trackEvent({
      event: ANALYTICS_SEARCH_FAILED,
      detailed_event: 'Search Failed',
      search_category: 'failed',
      search_term: this.updatedQueryText,
      original_search_term: this.originalQueryText,
      search_num_results: '0',
      event_data: {
        search_category: 'failed',
        search_num_results: '0',
        search_term: this.updatedQueryText,
      },
    });
  }
  /**
   *
   */
  ngOnInit() {
    // prevents registering listeners if search is not available for a site
    if (!this.hasSearch) {
      return null;
    }
    // Listen for isLoggedIn Change
    this.configService.isLoggedIn$
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(() => {
        // this is to recognize query params sent to app and select data source (or another options, like search from url)
        // TODO it needs to be moved to config service
        // We can also hide search if status API response is not available
        this.activatedRoute.queryParams
          .pipe(
            takeUntil(this.componentDestroyed$),
            switchMap(() => {
              return this.configService.getConfig();
            })
          )
          .subscribe((config: ObservedValueOf<Observable<any>>) => {
            this.options.config = config;
          });
      });

    this.isHandheld$ = this.responsiveService.isHandheld$();
    this.getBreakpointObs$ = this.responsiveService.getBreakpointObs$();

    this.isHandheld$.subscribe((isHandheld) => (this.isHandheld = isHandheld));
    this.showingResultsForLabel = this.translateService?.instant(
      'common.showing-results-for'
    );

    // trigger pre-search
    if (this.enablePreSearchModule) {
      this.preSearch();
    }
  }

  /**
   * after component is initialized, we check query string and trigger search if not empty
   */
  ngAfterViewInit() {
    this.subscription.add(
      this.location.subscribe((x: PopStateEvent) => {
        logger.debug('location:' + JSON.stringify(x));
        this.popstateChanged = true;
      })
    );

    this.activatedRoute.queryParams
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((params: Params) => {
        const dataQueryKey = 'query';
        const dataQueryTypeKey = 'queryType';
        const query = params[dataQueryKey];
        const queryType = params[dataQueryTypeKey];

        if (this.queryStringUpdated) {
          this.queryStringUpdated = false;
          return;
        }
        // trigger search in case query string is not empty
        // we should not trigger this if navigation starts after user clicks back/forward

        // if query changes before navigation changes page, we don't need to perform search
        // otherwise it will perform search, change navigation and search again
        // so we detect if user clicked back/forward and trigger search only if after such click navigation doesn't start
        // user may go back/forward with search opened, without url change
        if (
          query !== undefined &&
          query.trim() !== '' &&
          !(this.popstateChanged && this.navigationStart)
        ) {
          this.selectedToggle = queryType;
          // show modal
          this.openSearch(query);
          // perform search
          this.queryFromRouteChange = true;
          this.onTerm(query);
        }

        if (
          this.popstateChanged &&
          !query &&
          this.searchService.searchModalStatus$.value
        ) {
          this.closeSearch();
        }

        this.popstateChanged = false;
        this.navigationStart = false;
      });
  }

  /**
   * update query string after search
   * @param term query string to be added to url
   *
   */
  updateQueryString(term: string) {
    // this is to prevent opening modal if automatically opened query string
    this.queryStringUpdated = true;
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        query: term ? term : null,
        queryType: this.selectedToggle === 'full' && term ? 'full' : null,
      },
      // preserve the existing query params in the route
      queryParamsHandling: 'merge',
    });
  }

  /**
   * this reads exact match data and creats object for Exact match component
   */
  getExactMatchData(
    originalQueryText: string,
    fundInstant,
    instantDisplays
  ): ExactMatch {
    let results: ExactMatch = null;

    if (originalQueryText === 'about' || originalQueryText === 'status') {
      results = {
        type: 'status',
        data: null,
      };
      return results;
    }

    // returns exact match for funds
    if (!isEmpty(fundInstant[0])) {
      // TODO verify this condition
      results = {
        type: 'fund',
        data: fundInstant,
      };
      return results;
    }

    // returns exact match for literature and general
    if (instantDisplays.total > 0) {
      // TODO verify this condition
      results = {
        type: instantDisplays.hits[0].entityType,
        data: [instantDisplays],
      };
      return results;
    }

    return results;
  }

  /**
   * helper method for analytics - returning search category base on results type
   */
  getAnalyticsSearchCategory(exactMatchType: string): string {
    let analyticsExactMatchCat = 'all';

    switch (exactMatchType) {
      case 'fund':
        analyticsExactMatchCat = 'instant-fund';
        break;
      case 'fund_manager':
        analyticsExactMatchCat = 'instant-manager';
        break;
      case 'thematic_topic':
        analyticsExactMatchCat = 'instant-content';
        break;
    }
    return analyticsExactMatchCat;
  }

  /**
   * returns message displayed below search input field
   */
  getFoundMessage(): string {
    if (this.originalQueryText !== this.updatedQueryText) {
      return (
        'Showing results for&nbsp;<strong>' +
        this.updatedQueryText +
        '</strong>&nbsp;instead of&nbsp;<strong>' +
        this.originalQueryText +
        '</strong>'
      );
    }

    if (this.exactMatch && this.exactMatch.type !== '') {
      return (
        'We found an exact match for&nbsp;<strong>' +
        this.updatedQueryText +
        '</strong>'
      );
    }

    if (this.originalQueryText === this.updatedQueryText) {
      return (
        this.showingResultsForLabel +
        '&nbsp;<strong>' +
        this.updatedQueryText +
        '</strong>'
      );
    }

    return '';
  }

  /**
   * activate version and status subscriptions
   */
  activateSubscriptions() {
    this.searchService.loading$
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((loading) => {
        this.loading = loading;
        if (loading) {
          this.spinner.show('searchLoadingSpinner');
        } else {
          this.spinner.hide('searchLoadingSpinner');
        }

        this.cd.markForCheck();
      });

    // closes search modal with animation an delay
    this.searchService.searchModalStatus$
      .pipe(
        tap((status) => {
          logger.debug('inside searchModalStatus$: ' + status);
        }),
        takeUntil(this.componentDestroyed$),
        // this is to cancel previous request if new one comes - to analyze if can be improved
        // delay is to allow closing animation to run, before hiding modal
        switchMap((status) => of(status).pipe(delay(150)))
      )
      .subscribe((status) => {
        this.animateModal = status;
        this.showModal = status;
        this.cd.detectChanges();
        logger.debug(
          'searchModalStatus_timeout: ' + this.showModal + ' -> ' + status
        );
      });

    this.searchService.apiVer$
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((apiVer: string) => {
        this.apiVer = apiVer;
      });
  }

  /**
   * opens search takeover/modal
   */
  openSearch(term: string = '') {
    this.searchTerm = term; // this is to support search from URL/query string
    this.searchService.searchModalToggle(true);
    this.cd.detectChanges();
  }

  /**
   * closes search takeover
   */
  public closeSearch(clearTerm = false) {
    this.results = [];
    this.notFound = false;
    this.searchTerm = '';
    this.selectedToggle = null;
    if (clearTerm) {
      this.updateQueryString('');
    }

    // removes helper input which helps displaying keypad in mobile
    this.removeMobileInputHelper();

    this.searchService.loading$.next(false);
    this.searchService.searchModalToggle(false);
    this.isSearchActive.emit(false);
  }

  removeMobileInputHelper() {
    try {
      const searchContainerRoot = this.renderer2.selectRootElement(
        '#ftSearch',
        true
      );
      const searchMobileInputHelper = this.renderer2.selectRootElement(
        '.search-input-helper'
      );
      this.renderer2.removeChild(searchContainerRoot, searchMobileInputHelper);
    } catch {
      logger.debug('no .search-input-helper element found on the page');
      return;
    }
  }

  /**
   * changes selected tab
   */
  onToggleChange(toggle: ToggleState) {
    this.activeToggle = toggle;
    this.selectedToggle = toggle; // user manually selects another toggle state
    this.updateQueryString(this.updatedQueryText);
  }
  onSearch(value: string) {
    this.searchTerm = value;
    this.clearRecent = false;
    this.onTerm(value);
  }
  onClearRecentSearch() {
    this.searchService.clearRecentSearch();
    this.clearRecent = true;
  }
  /**
   * this is called when user inputs new search string
   */
  onTerm(term: string): boolean {
    this.options.term = term;
    this.options.page = 0;
    this.filters.resetFilters();

    // TODO rewrite this.
    // it should just emit term and not subscribe every time we change term
    const searchParams = {
      options: this.options,
      filters: this.filters.getFilters(),
    };

    if (term.trim() === '') {
      this.onClearResults();
      return false;
    }
    this.clearRecent = false;
    this.searchService.storeRecentSearch(term, this.recentStorageCount);
    this.searchService.search(searchParams);
    return true;
  }

  /**
   * this is called when search component initializes
   */
  private preSearch(): boolean {
    this.options.page = 0;

    const searchParams = {
      options: this.options,
      filters: '',
    };

    this.searchService.preSearch(searchParams);
    return true;
  }

  /**
   *  this is called after we select filter or request next page of results
   * @param page number of results page displayed
   */
  onFilter(page: number) {
    this.options.page = page;

    // TODO extract this to a separate method
    const searchParams = {
      options: this.options,
      filters: this.filters.getFilters(),
    };

    this.searchService.search(searchParams);
  }

  /**
   * clears results object and sets notFound/loading to false
   */
  onClearResults(): void {
    this.results = [];
    this.notFound = false;
    this.searchTerm = '';
    this.updateQueryString('');
    this.searchService.loading$.next(false);
  }

  /**
   * simplifies structure of 'aggregations' object to simplify processing
   * TODO verify this with new API data structure
   */
  processAggregations(aggregations: Aggregations): Aggregations {
    if (aggregations?.secondary) {
      // after filtering by one filter we get different nodes structure so we need to unify it
      Object.keys(aggregations.secondary).forEach((key) => {
        aggregations[key] = aggregations.secondary[key];
      });
    }
    return aggregations;
  }

  /**
   * verifies if results for any type has results
   * @param results response from search component
   */
  checkResults(results: FtSearchResponse[]) {
    if (results.length === 0) {
      return false;
    }

    // calculate sum of results in every category
    const hitsSum = results.reduce(
      (acc, result) => acc + (result.response?.hits?.hits?.length || 0),
      0
    );

    if (hitsSum === 0) {
      return false;
    }

    return true;
  }

  /**
   * method appends results if we search for page>1
   * API returns data only for requested number of records
   * TODO fix any and provide interface type
   * @param response response from search component
   */

  appendResults(response: FtSearchResponse[]) {
    const newResults = [];
    Object.keys(this.results)
      .filter((key) => this.results[key].response?.hits) // removing all nodes with empty data - elastis still returns it
      .forEach((key) => {
        const typeName: string = this.results[key].name;
        const typeResults = this.results[key].response;
        // create new array, shallow copy
        newResults[key] = {
          name: typeName,
          response: typeResults,
        };
        // shallow append results
        const newTypeResults: FtSearchResponse | boolean =
          response.find((searchResult) => searchResult.name === typeName) ||
          false;

        // using key - index is not correct when we have only one tab, like 'pages'
        if (newTypeResults) {
          newResults[key].response.hits.hits = newResults[
            key
          ].response.hits.hits.concat(newTypeResults?.response.hits.hits);
        }
      });
    return newResults;
  }

  /**
   * changes exact/full toggle
   */
  exactTogglePressed(): void {
    this.activeToggle = this.activeToggle === 'full' ? 'exact' : 'full';
  }

  /**
   * method removes parameter from URL
   * @param uri url string
   */
  removeParam(url: string, parameter: string): string {
    return removeParam(url, parameter);
  }

  ngOnDestroy(): void {
    // unsubscribe from Observables
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();

    this.subscription.unsubscribe();
  }

  /**
   * Closing search popup, when navigating same page in search results
   */
  @HostListener('click', ['$event'])
  onClick(event: MouseEvent) {
    const target: EventTarget = checkLink(event);
    if (target instanceof HTMLAnchorElement) {
      if (this.windowRef.location.href.includes(target.href)) {
        this.closeSearch();
      }
    }
  }
  // close dialog on escape
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
    if (event.key === 'Escape') {
      this.closeSearch();
    }
  }
}
