import { Product, Ratings, ShareClass } from '../models';
import {
  CalcTypeStd,
  ConfigurationId,
  FundShareClassId,
  PerformanceType,
  PerformanceView,
  SiteConfig,
} from '../../../types';
import { IconTheme, InfoItem, ISODate } from '@frk/eds-components';
import { Distribution } from '@products/models/distribution';

export interface PPSSState {
  data: PPSSStateData;
  config: PPSSStateConfig;
  siteConfig: SiteConfig;
  lastAction?: string;
  hasLoadedFullFeed?: boolean;
  isSiteIntl?: boolean; // TODO: can get this from siteConfig instead?
  isNjBest?: boolean; // TODO: can get this from siteConfig instead?
  hasDataMigrated?: boolean; // This flag is introduced to see if backened data migration is done in case of putnam.
}

export interface PPSSStateData {
  tableRows: ProductData[];
  filteredTableRows: ProductData[];
  asOfDates: AsOfDates;
}

interface AsOfDates {
  navAsOfDate: string;
  performanceAsOfDateMonthly: string;
  performanceAsOfDateMonthlyStd: ISODate;
  performanceAsOfDateQuarterly: string;
  performanceAsOfDateQuarterlyStd: ISODate;
  stdYieldThirtyDayAsofDate?: string;
  ratingsAsOfDate?: string;
}

export interface PPSSStateConfig {
  currentTable?: string;
  tabs: Tabs;
  performanceViewOptions: PerformanceViewOption[];
  suppressDetails?: SuppressDetails;
  identifierList?: string[];
  performanceStatusCaveat?: string;
  showFavoritesOnlySwitch: boolean; // To show favorite only switch button on ppss page.
  favoriteOnlySwitchState: SwitchState; // Set default favorite only state - off/on.
  showDownloadDataButton: boolean; // To show download Data button for excel or csv
  showExchangeRate?: boolean; // TO show exchange rate on ppss page
}

export interface Tabs {
  showPerformance: boolean;
  showYield: boolean;
  showRatings: boolean;
  showFundIdentifiers: boolean;
  showPrice?: boolean;
}

export interface PerformanceViewOption {
  name: PerformanceType;
  labelKey: string;
  headerLabelKey: string;
  periodEnds?: PerformanceView[];
  isDefault?: boolean;
}

export interface SuppressDetails {
  showMDashForUnder1Year?: boolean;
  showMDashForUnder6Months?: boolean;
}

export interface ProductData extends Omit<Product, 'shareClasses'> {
  shareClass?: ShareClass;
  distribution?: Distribution;
  fundFamily?: string;
  noLegend?: boolean;
}

export interface CalculationTypes {
  name: string;
  options: CalculationTypeOption[];
  groupHeaderLabel: string;
}

export interface CalculationTypeOption {
  key: string;
  labelKey: string;
  ariaControl: string;
  isActive: boolean;
}

export interface SwitchProps {
  labelKey: string;
  labelOffKey: string;
  labelOnKey: string;
  state: SwitchState;
}

export enum SwitchState {
  OFF = 'off',
  ON = 'on',
}
export enum SwitchStateFunds {
  YES = 'on',
  NO = 'off',
}

export interface ToggleProps {
  labelKey: CalcType;
  options: TogglePropsOption[];
  selected: CalcTypeStd;
  applicableValues?: Record<string, CalcTypeStd[]>;
  type: 'switch' | 'toggle';
}

export interface TogglePropsOption {
  label: string;
  value: CalcTypeStd;
}

export enum CalcType {
  SALES_CHARGES = 'products.sales-charges-toggle',
  NAV_MARKET_PRICE = 'products.nav-market-price-toggle',
  PURE_GROSS_NET = 'products.pure-gross-net-toggle',
}

export interface DropdownOption {
  value?: string;
  label?: string;
  link?: string;
  disabled?: boolean;
}

export interface RatingsCellData {
  ratingsPath: string;
  hybridPath: string;
  numberOfFundsPath?: string;
  hideNumberOfFunds?: boolean;
  showCategory?: boolean;
  showStarLabels?: boolean;
  showViewDetails?: boolean;
  theme?: IconTheme;
}

export interface FundNameData {
  fundName: string;
  suffix: string;
  secondSuffix?: string;
  highlightSuffix: boolean;
}

// NGC-13341 : Moved ppss cards here from ppss-card.component.ts
export interface PpssCardItem extends InfoItem {
  colId: string;
  hide?: boolean;
  ratings?: Ratings;
  rating?: number;
  hybridRating?: number;
  labelSmall?: boolean;
  additionalVal?: string;
  ratingTheme?: IconTheme;
  showRatingCategory?: boolean;
  showRatingViewDetails?: boolean;
}
export interface PpssCardSection<T = string> {
  sectionId: T;
  sectionTitle?: string;
  sectionSubTitle?: string;
  isExpandable?: boolean; // indicates if this should be inside expand-collapse component
  items: PpssCardItem[];
  isEven?: boolean;
  sectionPlacement?: string;
  sectionToolTip?: string;
}

export interface PpssCard {
  cardId: FundShareClassId; // unique id for this card
  fund: ProductData;
  fundDetail: FundNameData;
  sections: PpssCardSection[];
  downloadLabel?: string;
  downloadLink?: string; // link to file to download e.g. factsheet
  showSalesCharges?: boolean;
  isFavoriteFund?: boolean;
  hideFavorite?: boolean; // can override showing favorite button
  showRemoveFavorite?: boolean;
  showPerformanceAndMaintananceCharges?: boolean;
  showLegendIcons?: boolean;
  showProductTypeBadge?: boolean;
}
export interface FundNameNjBestData {
  fundName: string;
  row1: string;
  row2: string;
}

export interface PPSSGridContext {
  filteredRows: ProductData[];
  totalPages: number;
  currentPage: number;
  pageSize: number;
  selectedPerformanceCurrency?: string;
}

export interface ProductTypeViewOption {
  name: ConfigurationId;
  labelKey: string;
  headerLabelKey: string;
  isDefault?: boolean;
}
