<!-- Page Search -->
<div *ngIf="searchConfig?.enableSearchPage" class="page-search">
  <div edsGrid>
    <div class="container--wide page-search__container">
      <ng-container *ngTemplateOutlet="searchBody"></ng-container>
    </div>
  </div>
</div>
<!-- Modal Search -->
<div
  *ngIf="!searchConfig?.enableSearchPage"
  class="modal-container"
  [ngClass]="{ 'is-active': showModal }"
  [@openClose]="animateModal ? 'open' : 'closed'"
>
  <div
    id="siteSearch"
    class="modal"
    role="dialog"
    title="{{ 'common.search.title' | translate }}"
    aria-modal="true"
    [attr.aria-label]="'common.search.placeholder' | translate"
    [ngClass]="{ 'style-basic': headerStyle }"
    *ngIf="showModal"
    [attr.data-bkpoint]="getBreakpointObs$ | async"
  >
    <div class="modal-dialog" edsTrapFocus>
      <div class="modal-close">
        <button
          (click)="closeSearch(true)"
          class="modal-close__btn"
          aria-label="Close Search"
        >
          <span class="modal-close__btn--text">{{
            "common.search.close" | translate
          }}</span>
          <!--icon-->
          <eds-icon
            class="modal-close__icon"
            type="cross"
            size="small"
            aria-hidden="true"
          ></eds-icon>
        </button>
      </div>

      <div class="modal-body">
        <ng-container *ngTemplateOutlet="searchBody"></ng-container>
      </div>
    </div>
  </div>
  <div
    class="modal-tab-overlay"
    [ngClass]="{ 'modal-tab-overlay-active': showModal }"
  ></div>
</div>

<ng-template #searchSpinner>
  <div class="spinner-container" *ngIf="loading">
    <div class="loading-spinner">
      <ngx-spinner
        name="searchLoadingSpinner"
        bdColor="rgba(22,55,88,0.8)"
        size="small"
        color="#fff"
        type="line-scale-party"
        [fullScreen]="false"
      >
      </ngx-spinner>
    </div>
  </div>
</ng-template>

<ng-template #searchBody>
  <!--search bar-->
  <div class="search-input-container">
    <eds-search-bar
      [label]="'common.search.search' | translate"
      [placeholder]="
        searchPlaceholderText ||
        (((isHandheld$ | async) !== true
          ? 'common.search.placeholder'
          : 'common.search.placeholder-mobile'
        ) | translate)
      "
      [clearText]="'common.search.clear' | translate"
      [submitText]="'common.search.go' | translate"
      [initialTerm]="searchTerm"
      [minTermLength]="minTermLength"
      [debounceTime]="searchDebounceTime"
      (submitted)="onTerm($event)"
      (cleared)="onClearResults()"
      (cancelled)="closeSearch(true)"
      [animate]="false"
    ></eds-search-bar>
  </div>
  <!--/search bar-->

  <!--messages-->
  <div
    class="messages-toggle is-flex-left"
    *ngIf="results.length && !notFound && !error"
  >
    <ng-container *ngTemplateOutlet="searchSpinner"></ng-container>
    <div
      class="results-text"
      role="status"
      [class.has-toggle]="
        exactMatch && exactMatchTypes.includes(exactMatch.type)
      "
    >
      <h2
        *ngIf="!loading && foundMessage"
        class="search__result-title"
        [innerHtml]="foundMessage | safeInput"
      >
        {{ "" }}
      </h2>
      <div
        *ngIf="
          !loading && exactMatch && exactMatchTypes.includes(exactMatch.type)
        "
        class="result-seperator"
      ></div>
      <div
        *ngIf="
          !loading &&
          exactMatch &&
          exactMatchTypes.includes(exactMatch.type) &&
          (isHandheld$ | async) !== true
        "
        edsPadding
        edsPaddingLeft="sm"
        edsPaddingRight="xs"
      >
        {{ "common.search.viewing" | translate }}
      </div>
    </div>
    <!-- toggle component -->
    <div class="toggle-switch" *ngIf="!loading">
      <ft-exact-toggle
        *ngIf="(isHandheld$ | async) !== true"
        [showToggle]="exactMatch && exactMatchTypes.includes(exactMatch.type)"
        [activeToggle]="activeToggle"
        (toggleChanged)="onToggleChange($event)"
      >
      </ft-exact-toggle>
    </div>
  </div>

  <!-- this section is shown only before first search is done - to show loader/spinner before result section is shown -->
  <div
    class="messages-toggle is-flex"
    *ngIf="!results.length && !notFound && !error"
  >
    <ng-container *ngTemplateOutlet="searchSpinner"></ng-container>
  </div>

  <!-- TODO - waiting for design -->
  <div class="results-text" *ngIf="notFound">
    {{ "common.search.no-results" | translate }}
  </div>
  <div class="results-text" *ngIf="error">
    {{ "common.search.backend-error" | translate }}
  </div>
  <!--/messages-->

  <!--page results-->
  <div class="search-results">
    <ft-search-results
      *ngIf="results.length && !notFound && !error"
      [originalQueryText]="originalQueryText"
      [updatedQueryText]="updatedQueryText"
      [displayedPage]="displayedPage"
      [results]="results"
      [activeToggle]="activeToggle"
      [exactMatch]="exactMatch"
      [aggregations]="aggregations"
      [searchTerm]="searchTerm"
      (filtered)="onFilter($event)"
      edsTrapFocus
    ></ft-search-results>
    <ft-empty-search
      *ngIf="!results.length && enablePreSearchModule"
      (submitted)="onSearch($event)"
      [clearRecent]="clearRecent"
      [quickLinks]="quickLinks"
      (clearRecentSearchOn)="onClearRecentSearch()"
    >
    </ft-empty-search>
  </div>
  <!--/page results-->
</ng-template>
