import arSaConfig from 'dayjs/locale/ar-sa';
import deDeConfig from 'dayjs/locale/de';
import deAtConfig from 'dayjs/locale/de-at';
import deChConfig from 'dayjs/locale/de-ch';
import enAuConfig from 'dayjs/locale/en-au';
import enGbConfig from 'dayjs/locale/en-gb';
import esMxConfig from 'dayjs/locale/es-mx';
import etEeConfig from 'dayjs/locale/et';
import ltLtConfig from 'dayjs/locale/lt';
import lvLvConfig from 'dayjs/locale/lv';
import nlBeConfig from 'dayjs/locale/nl-be';
import ptBrConfig from 'dayjs/locale/pt-br';
import slSiConfig from 'dayjs/locale/sl';
import uzUzConfig from 'dayjs/locale/uz';
import zhCnConfig from 'dayjs/locale/zh-cn';
import zhTwConfig from 'dayjs/locale/zh-tw';

// WDE-6490: HACK import all dayjs locales in main bundle instead of dynamically
// This isn't ideal, as every site loads ALL locales when they only need 1
// However, it seems to stop a race condition where locale defaults to English
// TODO: long-term, we need a dynamic locale loading solution, that also handles race conditions
import 'dayjs/locale/cs';
import 'dayjs/locale/el';
import 'dayjs/locale/hu';
import 'dayjs/locale/ja';
import 'dayjs/locale/pl';
import 'dayjs/locale/ro';
import 'dayjs/locale/sk';

// Cloned / Modified Locales
import { customEnCaConfig } from './custom-moment-locales/en-ca';
import { customEnChConfig } from './custom-moment-locales/en-ch';
import { customEnKoConfig } from './custom-moment-locales/en-ko';
import { customEnOsConfig } from './custom-moment-locales/en-os';
import { customEnZaConfig } from './custom-moment-locales/en-za';
import { customEsEsConfig } from './custom-moment-locales/es-es';
import { customFrCaConfig } from './custom-moment-locales/fr-ca';
import { customFrFrConfig } from './custom-moment-locales/fr-fr';
import { customItItConfig } from './custom-moment-locales/it-it';
import { customNlNlConfig } from './custom-moment-locales/nl-nl';
import { customPtPtConfig } from './custom-moment-locales/pt-pt';
import { customZhHkConfig } from './custom-moment-locales/zh-hk';
import { cloneMomentLocale } from './custom-moment-locales/moment-utils';

/**
 * config for all localisation for web-platform
 *
 * NUMBER LOCALE ARTICLE
 * @see https://en.wikipedia.org/wiki/Decimal_separator
 *
 * DATE LOCALE ARTICLE
 * @see https://en.wikipedia.org/wiki/Date_format_by_country
 *
 * Nice database of both
 * https://www.ibm.com/support/knowledgecenter/en/SSS28S_8.1.0/XFDL/i_xfdl_r_locale_quick_reference.html
 * (es-MX example) https://www.ibm.com/support/knowledgecenter/en/SSS28S_8.1.0/XFDL/i_xfdl_r_formats_es_MX.html
 */

export interface HighchartsLanguageConfig {
  decimalPoint: string;
  thousandsSep: string;
}

export const HIGHCHARTS_DEFAULT_CONFIG: HighchartsLanguageConfig = {
  decimalPoint: '.',
  thousandsSep: ',',
};

export const HIGHCHARTS_EUROPEAN_CONFIG: HighchartsLanguageConfig = {
  decimalPoint: ',',
  thousandsSep: '.',
};

export interface NumbersLanguageConfig {
  decimalPoint: string;
  thousandsSep: string;
  fractionPrecision: number;
}

export const DEFAULT_CONFIG: NumbersLanguageConfig = {
  decimalPoint: '.',
  thousandsSep: ',',
  fractionPrecision: 2,
};

export const EUROPEAN_CONFIG = {
  decimalPoint: ',',
  thousandsSep: '.',
  fractionPrecision: 2,
};

export interface LanguageConfig {
  highcharts: HighchartsLanguageConfig;
  numbers: {
    standard: NumbersLanguageConfig;
    percentage: NumbersLanguageConfig;
  };
  moment: any; // TODO: need to work out Dayjs Locale type
}

// Examples of what delimiters should be can be found in many il8n projects
//    https://github.com/svenfuchs/rails-i18n/tree/master/rails/locale
//    https://github.com/angular/angular/tree/master/packages/common/locales
//    https://en.wikipedia.org/wiki/Common_Locale_Data_Repository
//
// If we ever wanted to we could automate this using this
//    https://github.com/angular/angular/tree/master/tools/gulp-tasks/cldr

// NOTE : Currency / Percentage can have different formatting
// aka switzerland currency == dot(.) and percentage == comma(,)
// COREWEB-3902 Switzerland - Decimal separator for NAV should be (.) in Pricing chart
//   The currency value will use Decimal Separator as dot(.) and the Percentage value will use Decimal Separator as comma(,).

/**
 * Locale settings for
 * see components\common\elements\charting\ft-charts-configs.js for implementation of actually global option config
 * // WORKAROUND: could set Highcharts.setOptions({lang: xxx}) directly here but might want to lazy load high charts in future
 */
export const LANG_CONFIG: { [locale: string]: LanguageConfig } = {
  'ar-sa': {
    // saudi arabia ARABIC [ar-sa]
    highcharts: HIGHCHARTS_DEFAULT_CONFIG,
    numbers: {
      standard: DEFAULT_CONFIG,
      percentage: DEFAULT_CONFIG,
    },
    moment: arSaConfig,
  },
  'cs-cz': {
    // Czech (Czech Republic) [cs-cz]
    highcharts: HIGHCHARTS_EUROPEAN_CONFIG,
    numbers: {
      standard: EUROPEAN_CONFIG,
      percentage: EUROPEAN_CONFIG,
    },
    moment: cloneMomentLocale('cs-cz', 'cs'),
  },
  'de-at': {
    // German (Austria) [de-at]
    highcharts: HIGHCHARTS_EUROPEAN_CONFIG,
    numbers: {
      standard: EUROPEAN_CONFIG,
      percentage: EUROPEAN_CONFIG,
    },
    moment: deAtConfig,
  },
  'de-ch': {
    // German (Switzerland) [de-ch]
    highcharts: HIGHCHARTS_EUROPEAN_CONFIG,
    numbers: {
      standard: EUROPEAN_CONFIG,
      percentage: EUROPEAN_CONFIG,
    },
    moment: deChConfig,
  },
  'de-de': {
    // German (Germany) [de]
    highcharts: HIGHCHARTS_EUROPEAN_CONFIG,
    numbers: {
      standard: EUROPEAN_CONFIG,
      percentage: EUROPEAN_CONFIG,
    },
    moment: deDeConfig,
  },
  // Greek (Greece) [el-gr] COREWEB-7027 - changed to default config to meet data preferences, but needs to be checked if data is OK.
  'el-gr': {
    highcharts: HIGHCHARTS_DEFAULT_CONFIG,
    numbers: {
      standard: DEFAULT_CONFIG,
      percentage: DEFAULT_CONFIG,
    },
    moment: cloneMomentLocale('el-gr', 'el'),
  },
  en: {
    // English (United States) [en-us]
    highcharts: HIGHCHARTS_DEFAULT_CONFIG,
    numbers: {
      standard: DEFAULT_CONFIG,
      percentage: DEFAULT_CONFIG,
    },
    moment: 'default-locale-hardcoded-into-framework',
  },
  'en-au': {
    // English (Australia) [en-au]
    highcharts: HIGHCHARTS_DEFAULT_CONFIG,
    numbers: {
      standard: DEFAULT_CONFIG,
      percentage: DEFAULT_CONFIG,
    },
    moment: enAuConfig,
  },
  'en-ca': {
    // English (Canada) [en-ca]
    highcharts: HIGHCHARTS_DEFAULT_CONFIG,
    numbers: {
      standard: DEFAULT_CONFIG,
      percentage: DEFAULT_CONFIG,
    },
    moment: customEnCaConfig,
  },
  'en-ch': {
    // English (Switzerland) [en-ch]
    highcharts: HIGHCHARTS_DEFAULT_CONFIG,
    numbers: {
      // COREWEB-3902 Switzerland - Decimal separator for NAV should be (.) in Pricing chart
      // The currency value will use Decimal Separator as dot(.) and the Percentage value will use Decimal Separator as comma(,).
      standard: EUROPEAN_CONFIG,
      percentage: EUROPEAN_CONFIG,
    },
    moment: customEnChConfig,
  },
  'en-cy': {
    // English (Cyprus) [en-cy]
    highcharts: HIGHCHARTS_DEFAULT_CONFIG,
    numbers: {
      standard: DEFAULT_CONFIG,
      percentage: DEFAULT_CONFIG,
    },
    moment: cloneMomentLocale('en-cy', 'en-gb'),
  },
  'en-dk': {
    // denmark Danish [en-dk]
    highcharts: HIGHCHARTS_DEFAULT_CONFIG,
    numbers: {
      standard: EUROPEAN_CONFIG,
      percentage: EUROPEAN_CONFIG,
    },
    moment: cloneMomentLocale('en-dk', 'en-gb'),
  },
  'en-fi': {
    // Finnish (Finland) [en-gb]
    highcharts: HIGHCHARTS_DEFAULT_CONFIG,
    numbers: {
      standard: EUROPEAN_CONFIG,
      percentage: EUROPEAN_CONFIG,
    },
    moment: cloneMomentLocale('en-fi', 'en-gb'),
  },
  'en-gb': {
    // English (United Kingdom) [en-gb]
    highcharts: HIGHCHARTS_DEFAULT_CONFIG,
    numbers: {
      standard: DEFAULT_CONFIG,
      percentage: DEFAULT_CONFIG,
    },
    moment: enGbConfig,
  },
  'en-hk': {
    // English (Hong Kong) [en-hk]
    highcharts: HIGHCHARTS_DEFAULT_CONFIG,
    numbers: {
      standard: DEFAULT_CONFIG,
      percentage: DEFAULT_CONFIG,
    },
    moment: cloneMomentLocale('en-hk', 'en-gb'),
  },
  'en-is': {
    // icelandic (Iceland) [en-is]
    highcharts: HIGHCHARTS_DEFAULT_CONFIG,
    numbers: {
      standard: EUROPEAN_CONFIG,
      percentage: EUROPEAN_CONFIG,
    },
    moment: cloneMomentLocale('en-is', 'en-gb'),
  },
  'en-ko': {
    // English (South Korea) [en-ko]
    highcharts: HIGHCHARTS_DEFAULT_CONFIG,
    numbers: {
      standard: DEFAULT_CONFIG,
      percentage: DEFAULT_CONFIG,
    },
    moment: customEnKoConfig,
  },
  'en-lu': {
    // English (Luxembourg) [en-lu]
    highcharts: HIGHCHARTS_DEFAULT_CONFIG,
    numbers: {
      // NB: Luxembourg officially supports both "." and "," decimal separators: https://en.wikipedia.org/wiki/Decimal_separator
      // UDS-500: PDS supplies "." separator for data, so we use DEFAULT_CONFIG
      standard: DEFAULT_CONFIG,
      percentage: DEFAULT_CONFIG,
    },
    moment: cloneMomentLocale('en-lu', 'en-gb'),
  },
  'en-my': {
    // English (Malaysia) [en-my]
    highcharts: HIGHCHARTS_DEFAULT_CONFIG,
    numbers: {
      standard: DEFAULT_CONFIG,
      percentage: DEFAULT_CONFIG,
    },
    moment: cloneMomentLocale('en-my', 'en-gb'),
  },
  'en-no': {
    // norway norwegian [en-no]
    highcharts: HIGHCHARTS_DEFAULT_CONFIG,
    numbers: {
      standard: EUROPEAN_CONFIG,
      percentage: EUROPEAN_CONFIG,
    },
    moment: cloneMomentLocale('en-no', 'en-gb'),
  },
  'en-os': {
    // English (Offshore US English) [en-os]
    highcharts: HIGHCHARTS_DEFAULT_CONFIG,
    numbers: {
      standard: DEFAULT_CONFIG,
      percentage: DEFAULT_CONFIG,
    },
    moment: customEnOsConfig,
  },
  'en-se': {
    // sweden (swedish) [en-se]
    highcharts: HIGHCHARTS_DEFAULT_CONFIG,
    numbers: {
      standard: EUROPEAN_CONFIG,
      percentage: EUROPEAN_CONFIG,
    },
    moment: cloneMomentLocale('en-se', 'en-gb'),
  },
  'en-sg': {
    // English (Singapore) [en-sg]
    highcharts: HIGHCHARTS_DEFAULT_CONFIG,
    numbers: {
      standard: DEFAULT_CONFIG,
      percentage: DEFAULT_CONFIG,
    },
    moment: cloneMomentLocale('en-sg', 'en-gb'),
  },
  'en-uz': {
    // UZNIF English [en-uz]
    highcharts: HIGHCHARTS_DEFAULT_CONFIG,
    numbers: {
      standard: DEFAULT_CONFIG,
      percentage: DEFAULT_CONFIG,
    },
    moment: cloneMomentLocale('en-uz', 'en-gb'),
  },
  'en-za': {
    // English (South Africa) [en-za]
    highcharts: HIGHCHARTS_DEFAULT_CONFIG,
    numbers: {
      standard: DEFAULT_CONFIG,
      percentage: DEFAULT_CONFIG,
    },
    moment: customEnZaConfig,
  },
  'es-es': {
    // Spanish (Spain) [es-es]
    highcharts: HIGHCHARTS_EUROPEAN_CONFIG,
    numbers: {
      standard: EUROPEAN_CONFIG,
      percentage: EUROPEAN_CONFIG,
    },
    moment: customEsEsConfig,
  },
  'es-mx': {
    // Spanish (Mexico / Mexican) [es-mx]
    highcharts: HIGHCHARTS_DEFAULT_CONFIG,
    numbers: {
      standard: DEFAULT_CONFIG,
      percentage: DEFAULT_CONFIG,
    },
    moment: esMxConfig,
  },
  'es-os': {
    // Spanish (Offshore Mexican) [es-os]
    highcharts: HIGHCHARTS_EUROPEAN_CONFIG,
    numbers: {
      standard: EUROPEAN_CONFIG,
      percentage: EUROPEAN_CONFIG,
    },
    moment: cloneMomentLocale('es-os', 'es-mx'),
  },
  'et-ee': {
    // Estonian (Estonia) [et-ee]
    highcharts: HIGHCHARTS_DEFAULT_CONFIG,
    numbers: {
      standard: DEFAULT_CONFIG,
      percentage: DEFAULT_CONFIG,
    },
    moment: etEeConfig,
  },
  'eu-inst': {
    // EU Institutional (English) [eu-inst]
    highcharts: HIGHCHARTS_DEFAULT_CONFIG,
    numbers: {
      standard: DEFAULT_CONFIG,
      percentage: DEFAULT_CONFIG,
    },
    moment: cloneMomentLocale('eu-inst', 'en-gb'),
  },
  'fr-be': {
    // French (Belgium French / Walloon) [fr-be]
    highcharts: HIGHCHARTS_EUROPEAN_CONFIG,
    numbers: {
      standard: EUROPEAN_CONFIG,
      percentage: EUROPEAN_CONFIG,
    },
    moment: cloneMomentLocale('fr-be', 'fr'),
  },
  'fr-ca': {
    // French (Canada) [fr-ca]
    highcharts: HIGHCHARTS_EUROPEAN_CONFIG,
    numbers: {
      standard: EUROPEAN_CONFIG,
      percentage: EUROPEAN_CONFIG,
    },
    moment: customFrCaConfig,
  },
  'fr-fr': {
    // French (France) [fr-fr]
    highcharts: HIGHCHARTS_EUROPEAN_CONFIG,
    numbers: {
      standard: EUROPEAN_CONFIG,
      percentage: EUROPEAN_CONFIG,
    },
    moment: customFrFrConfig,
  },
  'hu-hu': {
    // Hungarian (Hungary) [hu-hu]
    highcharts: HIGHCHARTS_EUROPEAN_CONFIG,
    numbers: {
      standard: EUROPEAN_CONFIG,
      percentage: EUROPEAN_CONFIG,
    },
    moment: cloneMomentLocale('hu-hu', 'hu'),
  },
  'it-it': {
    // Italian (Italy) [it]
    highcharts: HIGHCHARTS_EUROPEAN_CONFIG,
    numbers: {
      standard: EUROPEAN_CONFIG,
      percentage: EUROPEAN_CONFIG,
    },
    moment: customItItConfig,
  },
  'ja-ja': {
    // Japanese (Japan) [ja-ja]
    highcharts: HIGHCHARTS_DEFAULT_CONFIG,
    numbers: {
      standard: DEFAULT_CONFIG,
      percentage: DEFAULT_CONFIG,
    },
    moment: cloneMomentLocale('ja-ja', 'ja'), // COREWEB-7350 - use "moment" date format for Japan
  },
  'lt-lt': {
    // Lithuanian (Lithuania) [lt-lt]
    highcharts: HIGHCHARTS_EUROPEAN_CONFIG,
    numbers: {
      standard: EUROPEAN_CONFIG,
      percentage: EUROPEAN_CONFIG,
    },
    moment: ltLtConfig,
  },
  'lv-lv': {
    // Latvian (Latvia) [lv-lv]
    highcharts: HIGHCHARTS_EUROPEAN_CONFIG,
    numbers: {
      standard: EUROPEAN_CONFIG,
      percentage: EUROPEAN_CONFIG,
    },
    moment: lvLvConfig,
  },
  'nl-be': {
    // Dutch (Belgium Dutch / Flemish) [nl-be]
    highcharts: HIGHCHARTS_EUROPEAN_CONFIG,
    numbers: {
      standard: EUROPEAN_CONFIG,
      percentage: EUROPEAN_CONFIG,
    },
    moment: nlBeConfig,
  },
  'nl-nl': {
    // Dutch (Netherlands) [nl]
    highcharts: HIGHCHARTS_EUROPEAN_CONFIG,
    numbers: {
      standard: EUROPEAN_CONFIG,
      percentage: EUROPEAN_CONFIG,
    },
    moment: customNlNlConfig,
  },
  nordics: {
    // Nordics (SE, NO,DK,FI) (English) [nordics]
    highcharts: HIGHCHARTS_DEFAULT_CONFIG,
    numbers: {
      standard: EUROPEAN_CONFIG,
      percentage: EUROPEAN_CONFIG,
    },
    moment: cloneMomentLocale('nordics', 'en-gb'),
  },
  'pl-pl': {
    // Polish (Poland) [pl]
    highcharts: HIGHCHARTS_EUROPEAN_CONFIG,
    numbers: {
      standard: EUROPEAN_CONFIG,
      percentage: EUROPEAN_CONFIG,
    },
    moment: cloneMomentLocale('pl-pl', 'pl'),
  },
  'pt-br': {
    // Portuguese (Brazil) ['pt-br']
    highcharts: HIGHCHARTS_EUROPEAN_CONFIG,
    numbers: {
      standard: EUROPEAN_CONFIG,
      percentage: EUROPEAN_CONFIG,
    },
    moment: ptBrConfig,
  },
  'pt-os': {
    // Portuguese (Off Brazil Shore) ['pt-os']
    highcharts: HIGHCHARTS_EUROPEAN_CONFIG,
    numbers: {
      standard: EUROPEAN_CONFIG,
      percentage: EUROPEAN_CONFIG,
    },
    moment: cloneMomentLocale('pt-os', 'pt-br'),
  },
  'pt-pt': {
    // Portuguese (Portugal) ['pt-pt']
    highcharts: HIGHCHARTS_EUROPEAN_CONFIG,
    numbers: {
      standard: EUROPEAN_CONFIG,
      percentage: EUROPEAN_CONFIG,
    },
    moment: customPtPtConfig,
  },
  'ro-ro': {
    // Romanian (Romania) [ro-ro]
    highcharts: HIGHCHARTS_EUROPEAN_CONFIG,
    numbers: {
      standard: EUROPEAN_CONFIG,
      percentage: EUROPEAN_CONFIG,
    },
    moment: cloneMomentLocale('ro-ro', 'ro'),
  },
  'sk-sk': {
    // Slovakian (Slovakia) [sk-sk]
    highcharts: HIGHCHARTS_EUROPEAN_CONFIG,
    numbers: {
      standard: EUROPEAN_CONFIG,
      percentage: EUROPEAN_CONFIG,
    },
    moment: cloneMomentLocale('sk-sk', 'sk'),
  },
  'sl-si': {
    // Slovenian (Slovenia) [sl-si]
    highcharts: HIGHCHARTS_EUROPEAN_CONFIG,
    numbers: {
      standard: EUROPEAN_CONFIG,
      percentage: EUROPEAN_CONFIG,
    },
    moment: slSiConfig,
  },
  'uz-uz': {
    // UZNIF Uzbek [uz-uz]
    highcharts: HIGHCHARTS_DEFAULT_CONFIG,
    numbers: {
      standard: DEFAULT_CONFIG,
      percentage: DEFAULT_CONFIG,
    },
    moment: uzUzConfig,
  },
  'zh-cn': {
    // Chinese (Simplified, PRC) [zh-cn]
    highcharts: HIGHCHARTS_DEFAULT_CONFIG,
    numbers: {
      standard: DEFAULT_CONFIG,
      percentage: DEFAULT_CONFIG,
    },
    moment: zhCnConfig,
  },
  'zh-hk': {
    // Chinese (Traditional, Hong Kong) [zh-hk]
    highcharts: HIGHCHARTS_DEFAULT_CONFIG,
    numbers: {
      standard: DEFAULT_CONFIG,
      percentage: DEFAULT_CONFIG,
    },
    moment: customZhHkConfig,
  },
  'zh-tw': {
    // Chinese (Traditional, Taiwan) [zh-tw]
    highcharts: HIGHCHARTS_DEFAULT_CONFIG,
    numbers: {
      standard: DEFAULT_CONFIG,
      percentage: DEFAULT_CONFIG,
    },
    moment: zhTwConfig,
  },
};
