import { ProductType } from '@products/types';
import { CommonConfig, GlobalId, LiteratureAuditConfig, SegmentId } from '.';
import { FundShareClassId } from '../types';
import { StrategyConfiguration } from './strategy-config.type';

export type SegmentConfig = {
  label: string;
  subLabel?: string; // TODO: this needs added to BR config
  id: SegmentId;
  // Deprecated
  // We now get default segment from channel json, so we can use it on first call to resourceapi for new users
  isDefault: boolean;
  terms: boolean;
  termsMustRead?: boolean;
  termsWithSignIn?: boolean;
  externalLink: string;
  insightAudiences_csv?: string;
  declineUrl?: string;
  isHiddenInBanner?: boolean;
  isHiddenInSwitcher?: boolean;
  isCustomMegaMenu?: boolean;
  isHideLogin?: boolean;
};

export type FundSegmentConfiguration = any; // TODO

export type FundConfiguration = {
  distributor?: any; // Germany only. "Vertriebspartner" translates as both "Sales Partner" and "Distribution Partner"
  'financial-professionals'?: any; // most Core-B+ sites
  gatekeeper?: any; // most Core-B+ sites going forward
  institutional?: any; // mix of Core-B+ and dedicated Inst sites
  investor?: any; // almost all sites
  shariah?: any; // Malaysia

  all?: any; // configurations across segments
  commonActiveLists?: FundShareClassId[];
};

export type LiteratureSegmentConfiguration = any; // TODO
export interface LiteratureConfigUrl extends LiteratureConfiguration {
  downloadExcelIdentifiers_csv?: string;
  litdetail?: string;
  litordercheckout?: string;
  litorderhistorydetail?: string;
  orderconfirm?: string;
  orderhistorylist?: string;
  ordersuccess?: string;
  ordercheckoutaddress?: string;
  litlisting?: string;
  myliteraturelanding?: string;
  manageEmailPreference?: string;
  all?: any;
  languageMapping_csv?: string;
}

export type LiteratureConfiguration = {
  [index in SegmentId]?: LiteratureSegmentConfiguration;
};

type SiteParameters = {
  countryHubLink: string;
  footerLogo: string;
  headerLogo: string;
  headerMicrositeTextLogo: string;
  headerMicrositeLogoHref: string;
  logoAltText: string;
  footerDisclaimerOverride: string;
};

type CompanyInfo = {
  info?: string;
  href?: string;
  src?: string;
  alt?: string;
};

export type LeavingSite = {
  leavingSiteContent?: string;
  leavingSiteTitle?: string;
};

type SiteParams = {
  siteParameters?: SiteParameters;
  companyInfo?: CompanyInfo;
  leavingSite?: LeavingSite;
};

export interface TaxInfoConfiguration {
  filters_csv?: string;
}

export type SiteConfiguration = {
  site: CommonConfig;
  funds: FundConfiguration;
  segments: SegmentConfig[];
  languages: LanguageData[];
  literature: LiteratureConfigUrl;
  siteParams: SiteParams;
  search?: SiteSearchParams;
  taxinfo?: any;
  literatureAudit?: LiteratureAuditConfig;
};

export type LanguageData = {
  name?: string;
  displayName?: string;
  locale?: string;
  url?: string;
  contentType?: string;
};

export type GlobalConfig = {
  siteConfiguration?: SiteConfiguration;
  multilingualMap?: MultilingualMap[];
  strategyConfiguration?: StrategyConfiguration[];
};
export interface MultilingualMap {
  languageVersion: string;
  pathToPage: string;
}

export type SiteSearchParams = {
  locale?: string;
  collation?: string;
  collationInsights?: string;
  sort?: string;
  doNotSpellCheck?: string;
  selectedTab?: string;
  hideTabs?: string;
};

export type SiteSearchConfig = {
  minTermLength: number;
  searchDebounceTime: number;
  enableExtendedSearchLogs: boolean;
  enablePreSearchModule: boolean;
  enableSearchPage?: boolean;
  searchPageUrl?: string;
};

export type FavoritesConfig = {
  hasFavorites: boolean;
  useLocalStorage: boolean;
};
export type FavoritesFundType = {
  fundId: FundShareClassId;
  userId: string;
  siteId: string;
  envId: string;
  fundName: string;
};

export type FavoritesFundRequestTypeDto = {
  CONTACT_GLOBAL_ID: GlobalId;
  TIS_SHARECLASS_CODE: FundShareClassId;
  PRODUCT_TYPE: ProductType;
  FUND_NAME: string;
  FUND_URL: string;
  ADDEDBY: string;
  UPDATEDON: string;
  STATUS: FavoritesStatus;
};

export type FavoritesRequestDetail = {
  fundName: string;
  productType: ProductType;
  fundUrl: string;
  fundShareClassId?: FundShareClassId;
};

export enum FavoritesStatus {
  'ACTIVE' = 'Active',
  'INACTIVE' = 'Inactive',
}

export type FavoriteSaveResponseDto = {
  Status?: string;
  Message?: string;
  Statuscode?: number;
};

/**
 * Links used in userRoleItems, countryItems, langItems and siteNavItems
 */
export interface SimplyLinkItem {
  link: string;
  href: string;
  eventParams?: {
    link_text: string;
  };
}
