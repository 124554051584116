import { Component, Input, OnInit, OnDestroy, Inject } from '@angular/core';
import { Page } from '@bloomreach/spa-sdk';
import {
  AnalyticsService,
  ResponsiveService,
  RoleSelector,
  CardIndex,
  DropdownItem,
  LanguageSelector,
} from '@frk/eds-components';
import { LanguageSelectorService, RoleSelectorService } from '@services';
import { SegmentId, SimplyLinkItem } from '@types';
import { Logger } from '@utils/logger';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { WINDOW } from '@ng-web-apis/common';

const logger = Logger.getLogger('RollSelectorBannerComponent');

@Component({
  selector: 'ft-role-selector-banner',
  templateUrl: './role-selector-banner.component.html',
})
export class RoleSelectorBannerComponent implements OnInit, OnDestroy {
  @Input() page!: Page;
  public isVisible$: Observable<boolean>;
  public isMobile$: Observable<boolean>;
  public isHandheld$: Observable<boolean>;
  public roles: Array<CardIndex>;

  public siteLanguages: Array<SimplyLinkItem>;
  public languageOptions: LanguageSelector;

  public title: string;
  public preTitle: string;
  public summary: string;

  public imageSrc: string;
  public imageTransparentBg = true;
  public transparencyRatio: '40' | '60' | '50' = '50';

  private selectedSegmentId: SegmentId = null;
  private defaultSegmentId: SegmentId = null;
  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private roleSelectorService: RoleSelectorService,
    private responsiveService: ResponsiveService,
    private languageSelectorService: LanguageSelectorService,
    private analyticsService: AnalyticsService,
    @Inject(WINDOW) readonly windowRef: Window
  ) {}

  ngOnInit(): void {
    this.isMobile$ = this.responsiveService.isMobile$();
    this.isHandheld$ = this.responsiveService.isHandheld$();
    this.isVisible$ = this.roleSelectorService.showRoleSelectorBanner$();
    this.title = this.roleSelectorService.getBannerTitle();
    // Disabling pre-title, as it is no longer required on gateway banner.
    // this.preTitle = this.roleSelectorService.getBannerPreTitle();
    this.summary = this.roleSelectorService.getBannerSummary();

    this.imageSrc = this.roleSelectorService.getBannerImage();
    this.transparencyRatio = this.roleSelectorService.getTransparencyRatio();
    this.imageTransparentBg = this.roleSelectorService.isBgTransparent();

    this.siteLanguages = this.languageSelectorService.getConvertedLanguages();

    if (this.siteLanguages.length) {
      this.languageOptions = {
        title: this.languageSelectorService.getChannelLanguageLabel(this.page),
        items: this.siteLanguages,
      };
    }

    this.roleSelectorService
      .getRoleSelectorOptions$()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((opts: RoleSelector): void => {
        // this.roleSelectorOptions = opts;
        this.defaultSegmentId = opts.preselectedValue.value;
        this.roles = opts.items.map((item: DropdownItem) => {
          return {
            title: item.label,
            summary: item.subLabel,
            theme: 'dark', // this will change to optional in EDS and will be removed
            event: item.value,
          };
        });
      });
  }

  /**
   * selecting new segment/role
   * @param event selected card with role
   */
  public roleClicked(event: CardIndex): void {
    logger.debug('Role Clicked');
    this.analyticsService.trackEvent({
      event: 'callout_click',
      detailed_event: 'Callout Clicks',
      event_data: {
        category: '',
        link_id: '',
        link_text: event.title,
        link_type: this.analyticsService.getLinkType(
          this.windowRef.location.href
        ),
        link_url: this.windowRef.location.href,
      },
      link_text: event.title,
      link_url: this.windowRef.location.href,
    });
    this.selectedSegmentId =
      event && event.event ? (event.event as SegmentId) : this.defaultSegmentId;

    // spinner for Gateway to HomePage Load after selecting the role
    this.roleSelectorService.publishHomePageLoadState(true);
    this.roleSelectorService.confirmSelection(this.selectedSegmentId);
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
