import { DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { AppStateService, SessionService } from '@services';
import { ViewModeService } from '@services/view-mode.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  private isUS = true;
  title = 'Franklin Templeton';
  isEditMode = false;

  constructor(
    private viewModeService: ViewModeService,
    private appStateService: AppStateService,
    @Inject(DOCUMENT) private documentRef: Document,
    private sessionService: SessionService
  ) {
    this.isEditMode = this.viewModeService.isEditMode();
    this.isUS = this.appStateService.getChannel() === 'en-us';

    if (!this.isUS) {
      return;
    } else {
      const param = this.documentRef.location.search;
      if (param.indexOf('login=true') > -1) {
        this.sessionService.auth0Authorize({
          ui_locales: this.appStateService.getHtmlLang(),
          screen_hint: 'signin',
        });
      } else if (param.indexOf('signup=true') > -1) {
        this.sessionService.auth0Authorize({
          ui_locales: this.appStateService.getHtmlLang(),
          screen_hint: 'signup',
        });
      }
    }
  }
}
