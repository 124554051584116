import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { Configuration, initialize, Page } from '@bloomreach/spa-sdk';
import { LOCATION } from '@ng-web-apis/common';
import { CaveatService } from '@products/caveats/services/caveat.service';
import {
  AppStateService,
  EnvConfig,
  EnvConfigService,
  GlobalConfigService,
  IUserProfile,
  IUserProfileInfo,
  LabelsService,
  ProfileService,
  SchemaService,
  SegmentService,
  StorageService,
} from '@services';
import { ComponentMapping } from '@services/module-loader.config';
import { ModuleLoaderService } from '@services/module-loader.service';
import { TranslateService } from '@shared/translate/translate.service';
import { IAnalyticsDataStore, SegmentId } from '@types';
import { LabelLoader } from '@utils/label-loader';
import { Logger } from '@utils/logger';
import { replaceTokens } from '@utils/text/string-utils';
import {
  EMPTY,
  from,
  Observable,
  ReplaySubject,
  Subject,
  TimeoutError,
} from 'rxjs';
import {
  catchError,
  concatAll,
  debounceTime,
  filter,
  map,
  switchMap,
  take,
  takeUntil,
  tap,
  timeout,
} from 'rxjs/operators';
import snakeCase from 'lodash/snakeCase';

import {
  LabelLoader as AltsLabelLoader,
  LabelsService as AltsLabelService,
} from '@ft-alts/ui-components/src/lib/common-lib';

const logger = Logger.getLogger('PageContainerService');

export enum AnonymousAccess {
  ATTESTED = 'attested',
  ALLOWED = 'non-investor',
  NONE = 'none',
}

export type PageConfig = {
  page?: Page;
  configuration: Configuration;
  mapping?: ComponentMapping;
  segmentOk?: boolean;
  anonymousAccess?: string;
  allowedSegment?: SegmentId;
};
export interface IsGateway {
  isGateway: boolean;
  gatewayURL: string;
}
@Injectable({
  providedIn: 'root',
})
export class PageContainerService implements OnDestroy {
  public page$ = new ReplaySubject<PageConfig>(1);
  private currentUrl: string;
  private profileKey: string;
  private profile$: Observable<IUserProfile>;
  private newPage$: ReplaySubject<string> = new ReplaySubject<string>(1);
  private unsubscribe$: Subject<void> = new Subject<void>();
  private envConfig: EnvConfig;

  // HACK: this allows us to spy+mock BR SDK's initialize()
  private callResourceApi = initialize;

  // prettier-ignore
  constructor( // NOSONAR - typescript:S107 - we need to accept more than 7 parameters in the constructor.
    private moduleLoader: ModuleLoaderService,
    private caveatService: CaveatService,
    private labelLoader: LabelLoader,
    private translateService: TranslateService,
    private labelsService: LabelsService,
    private globalConfigService: GlobalConfigService,
    private appStateService: AppStateService,
    private envConfigService: EnvConfigService,
    private profileService: ProfileService,
    private storageService: StorageService,
    private schemaService: SchemaService,
    private segmentService: SegmentService,
    private meta: Meta,
    private altsLabelLoader: AltsLabelLoader,
    private altsLabelService: AltsLabelService,
    @Inject(LOCATION) readonly locationRef: Location
  ) {
    // listen for profile
    this.profile$ = this.profileService.getUserProfile$();

    // listen for profile changes (which will include segment changes) and call newPage()
    this.profileService
      .getProfileChanges$()
      ?.pipe(takeUntil(this.unsubscribe$))
      .subscribe((profileKey: string): void => {
        this.profileKey = profileKey;
        logger.debug('new profile', profileKey, this.currentUrl);
        // this should refresh existing page, but with new segment characteristics
        if (this.currentUrl) {
          logger.debug(
            'call newPage() internally on profile change',
            this.currentUrl
          );
          this.newPage(this.currentUrl);
        }
      });

    this.init();
  }

  private async init(): Promise<void> {
    // wait until environment config has loaded
    await this.envConfigService.loadEnvConfig();
    this.envConfig = this.envConfigService.getEnvConfig();

    // listen for new page urls and call resourceapi
    this.newPage$
      .pipe(
        tap((url: string): void => {
          logger.debug('init(): newPage$ emitted url:', url);
        }),
        map(this.getPageFromResourceApi), // initiate Promise for resourceapi request
        map(this.wrapTimeout$), // add timeout, catch and handle errors
        concatAll(), // this makes sure requests run sequentially
        filter((pageConfig: PageConfig): boolean => pageConfig !== undefined), // filter out undefined values ie no profileKey
        debounceTime(100), // short debounce reduces flash of page rendering
        switchMap(this.getComponentMappings$) // load cmp mappings for page
      )
      .subscribe(this.handleNewPageResult);
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public newPage(url: string): void {
    logger.debug('newPage()', url);
    this.newPage$.next(url);
  }

  private getPageFromResourceApi = async (url: string): Promise<PageConfig> => {
    logger.debug('getPageFromResourceApi()', url);
    this.currentUrl = url;

    logger.debug('getPageFromResourceApi() profileKey', this.profileKey);
    if (this.profileKey) {
      // dont send a request until the profileKey is set
      const pageConfig: PageConfig = {
        configuration: {
          ...this.appStateService.getBrConfiguration(),
          debug: logger.willLogDebug(),
          path: url,
        },
      };

      const page: Page = await this.callResourceApi(pageConfig.configuration); // initialize from spa-sdk calls the resourceapi
      logger.debug('processing new resourceApi response', page);

      // set pageConfig props
      pageConfig.page = page;
      pageConfig.segmentOk = this.segmentService.checkSegmentRestrict(page);
      pageConfig.anonymousAccess = this.checkPageAnonymousAccess(page);
      pageConfig.allowedSegment = this.getAllowedActor(page);

      // TODO: handle labels etc better
      this.labelsService.loadCommonLabels(page);
      this.labelsService.loadLanguageLabels(page);
      this.loadLabelModels(page);
      // load labels before config services start, so they're already available
      this.globalConfigService.initialiseFromPage(page);
      this.labelLoader.loadLabelOverrides();
      this.updateLinks(page); // need to fix links for pagemodel 1.0
      this.iterateAndReplaceTokens(page, this.envConfig);
      this.schemaService.removeStructuredData();

      return pageConfig;
    }
  };

  private getComponentMappings$ = (
    pageConfig: PageConfig
  ): Observable<PageConfig> =>
    this.moduleLoader.getMapping$(pageConfig.page).pipe(
      take(1),
      map(
        (mapping: ComponentMapping): PageConfig => {
          logger.debug('mapping', mapping, Object.keys(mapping));
          pageConfig.mapping = mapping;
          return pageConfig;
        }
      )
    );

  // takes a Promise, turns it into an Observable, adds a timeout, then catches errors
  private wrapTimeout$ = (
    apiPromise: Promise<PageConfig>
  ): Observable<PageConfig> =>
    from(apiPromise).pipe(
      timeout(this.envConfig.resourceApiTimeoutMs),
      catchError(this.handleError$)
    );

  private handleError$ = (err: Error): Observable<never> => {
    // If Request has timed out
    if (err instanceof TimeoutError) {
      logger.debug('TimeoutError', err);
      // Catch Timeout error
      if (this.envConfig.errorPage) {
        this.meta.addTag({
          name: 'prerender-status-code',
          content: '408',
        });

        // Redirect user to time out page.
        this.locationRef.href = this.envConfig.errorPage;
      }
    } else if (err instanceof HttpErrorResponse) {
      logger.debug('HttpErrorResponse', err);
      // Non 200 ok http response
      if (this.envConfig.errorPage) {
        this.meta.addTag({
          name: 'prerender-status-code',
          content: '' + err.status,
        });
        // Redirect user to error page.
        this.locationRef.href = this.envConfig.errorPage;
      }
    } else {
      logger.error(err);
    }
    return EMPTY; // this means the emit is ignored
  };

  private handleNewPageResult = (pageConfig: PageConfig): void => {
    logger.debug('handleNewPageResult()', pageConfig);
    this.moduleLoader.initializeFootnotes(pageConfig.page, this.caveatService);
    this.page$.next(pageConfig);
  };

  /**
   * Checking Anonymous page access
   * @param page - SPA Page object
   */
  private checkPageAnonymousAccess(page: Page): string {
    const gatewayModel = page
      ?.getComponent('page-config', 'gateway')
      ?.getModels()?.gateway;
    if (
      gatewayModel?.anonymousAccess
        ?.toLowerCase()
        .includes(AnonymousAccess.ATTESTED)
    ) {
      return AnonymousAccess.ATTESTED;
    }
    if (
      gatewayModel?.anonymousAccess
        ?.toLowerCase()
        .includes(AnonymousAccess.ALLOWED)
    ) {
      return AnonymousAccess.ALLOWED;
    }
    return AnonymousAccess.NONE;
  }

  /**
   * Returns first allowed segment configured for page.
   * @param page - Page
   */
  private getAllowedActor(page: Page): SegmentId {
    const gatewayModel = page
      ?.getComponent('page-config', 'gateway')
      ?.getModels()?.gateway;
    if (!gatewayModel) {
      // Return undefined when gateway model is not set.
      return undefined;
    }
    const allowedSegments = Object.keys(gatewayModel).filter((key: string) => {
      return (
        gatewayModel[key] &&
        (key.includes('public') || key.includes('loggedIn'))
      );
    });
    logger.debug(allowedSegments);
    // Setting first allowed segment.
    const segment = snakeCase(
      allowedSegments[0]?.replace(/(public|loggedIn)/, '')
    ).toUpperCase();
    return SegmentId[segment];
  }

  /**
   * Iterate over resource api response and replace tokens from strings
   * @param page - page object.
   * @param envConfig - EnvConfig
   */
  private iterateAndReplaceTokens(page: Page, envConfig: EnvConfig) {
    Object.keys(page).forEach((pageKey) => {
      if (page[pageKey] !== null && typeof page[pageKey] === 'object') {
        this.iterateAndReplaceTokens(page[pageKey], envConfig);
        return;
      }
      // Profile condition causes issue with replacing tokens when it is initiated before profile is set.
      // This might happen during logout. Profile is not mandatory field for replaceTokens and can be undefined.
      if (typeof page[pageKey] === 'string' && envConfig) {
        if (
          page[pageKey].match(
            /\$(ENV|CONFIG|LABEL|PROFILE)(\(|\[)(\w+(?:.\w+)*)(\)|\])|data-signin-required="true"/g
          )
        ) {
          // Replace tokens if they match criteria.
          this.profile$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((profile) => {
              page[pageKey] = replaceTokens(
                page[pageKey],
                envConfig,
                profile,
                this.translateService
              );
            });
        }
      }
    });
  }

  /**
   *  Set Labels for translation in labelLoader
   * @param page - page object
   */
  loadLabelModels(page: Page) {
    const models = page.getComponent('site-configuration')?.getModels();
    if (models && Object.keys(models).length > 0) {
      this.labelLoader.setLabelTranslation(models);
      // Intializing alts Labels.
      if (models['ft.core.ftAlts']) {
        this.altsLabelLoader?.setLabelTranslation(models);
        this.altsLabelService?.loadPDSLabels$().subscribe();
      }
    }
  }

  public getGateway(): IsGateway {
    return {
      isGateway: this.appStateService.getIsGateway(),
      gatewayURL: this.appStateService.getGatewayUrl(),
    };
  }

  // Pagemodel 1.0 doesnt return channel in links so need to add them.
  private updateLinks(page: any) {
    const baseUrl = this.appStateService.getSpaBaseUrl();
    if (baseUrl) {
      Object.values(page.model.page).forEach((val: any) => {
        if (val.type === 'menu') {
          logger.debug('Fixing menu links');
          this.fixMenu(val.data?.siteMenuItems, baseUrl);
        } else {
          logger.debug('Fixing links', val);
          this.fixLinks(val, baseUrl);
        }
      });
    }
  }

  private fixMenu(menu: any[], baseUrl): void {
    if (menu) {
      menu.forEach((item) => {
        this.fixLinks(item, baseUrl);
        this.fixMenu(item.childMenuItems, baseUrl);
      });
    }
  }

  private fixLinks(val, baseUrl): void {
    if (
      val.links?.site?.type === 'internal' &&
      !val.links.site.href?.startsWith(baseUrl)
    ) {
      val.links.site.href = baseUrl + val.links.site.href;
    }
  }
}
