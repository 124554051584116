import { Injectable, OnDestroy } from '@angular/core';
import { AnalyticsService, CustomDimensions } from '@frk/eds-components';
import { UtmCampainDetails } from '@types';
import { ReplaySubject, Subject } from 'rxjs';
import { IsLoginUser, IUserProfile } from './profile.interface';
import { ProfileService } from './profile.service';
import { SiteConfigService } from './site-config.service';
import { StorageService } from './storage.service';
import { Logger } from '@utils/logger';
import { take, takeUntil } from 'rxjs/operators';
import merge from 'lodash/merge';

/**
 * The PageViewService is responsible for sending page view events to GA.
 * The triggerPageView() is called on a new page from the page-container component.
 * But if the page is a product detail page or an article page then we wait for additional data to be added.
 * Also, data is only sent when we have a validated user profile so the user data can be merged in.
 */
interface PageAnalyticsMetadata {
  assetClass: [];
  fundBrand: [];
  investmentTeam: [];
  investmentTheme: [];
}

interface AnalyticsPageData {
  title?: string;
  pageType?: string;
  language?: string;
  analyticsMetadata?: PageAnalyticsMetadata;
}

interface PageViewData {
  url: string;
  pageData?: AnalyticsPageData;
}

export interface AnalyticsProductData {
  fundCategory: string;
  productType: string;
  fundName: string;
  investmentManager: string;
  shareClassName: string;
  fundTicker: string;
  investmentGroup: string;
}

const logger = Logger.getLogger('PageViewService');
@Injectable({
  providedIn: 'root',
})
export class PageViewService implements OnDestroy {
  private data: CustomDimensions;
  private userData$ = new ReplaySubject<CustomDimensions>(1);
  private lastUrl: string;
  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private analyticsService: AnalyticsService,
    profileService: ProfileService,
    private siteConfigService: SiteConfigService,
    private storageService: StorageService
  ) {
    profileService
      .getUserProfile$()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((userProfile) => {
        // userProfile is undefined on gateway page on first visit
        if (userProfile) {
          this.setUserData(userProfile);
        } else {
          this.userData$.next({});
        }
      });
  }

  private sendAnalytics(data: CustomDimensions) {
    if (data.page_location !== this.lastUrl) {
      // avoid duplicates
      this.lastUrl = data.page_location;
      this.userData$.pipe(take(1)).subscribe((userData) => {
        const finalData = Object.assign({}, data, userData);
        logger.debug(finalData);
        this.analyticsService.pageViewWithCustomDimensions(finalData, {
          url: finalData.page_location,
          title: finalData.page_title,
        });
      });
    }
  }
  /**
   * called from page-container component
   * Create Custom Dimensions to be sent before any other event
   * information.
   */
  public triggerPageView(
    pageViewData: PageViewData,
    waitFlag: boolean,
    utmValue: Partial<UtmCampainDetails>,
    fundName: string
  ): void {
    const analyticsMetadata: PageAnalyticsMetadata =
      pageViewData.pageData?.analyticsMetadata;
    this.data = {
      page_location: pageViewData.url,
      event: 'page_load_started',
      detailed_event: 'Page Load Started',
      page_title: pageViewData.pageData?.title,
      page_type: pageViewData.pageData?.pageType,
      language_code: pageViewData.pageData?.language,
      country_code: this.siteConfigService?.product?.site?.country,
      market_code: this.siteConfigService?.product?.site?.country, // DUPE
      utm_source: utmValue?.utm_source,
      utm_medium: utmValue?.utm_medium,
      utm_campaign: utmValue?.utm_campaign,
      utm_content: utmValue?.utm_content,
      asset_class: this.joinAnalyticsMetadata(analyticsMetadata?.assetClass),
      fund_brand: this.joinAnalyticsMetadata(analyticsMetadata?.fundBrand),
      fund_name: fundName,
      investment_team: this.joinAnalyticsMetadata(
        analyticsMetadata?.investmentTeam
      ),
      investment_theme: this.joinAnalyticsMetadata(
        analyticsMetadata?.investmentTheme
      ),
      page_data: {
        asset_class: this.joinAnalyticsMetadata(analyticsMetadata?.assetClass),
        fund_brand: this.joinAnalyticsMetadata(analyticsMetadata?.fundBrand),
        investment_theme: this.joinAnalyticsMetadata(
          analyticsMetadata?.investmentTheme
        ),
        language_code: pageViewData.pageData?.language,
        page_location: pageViewData.url,
        page_title: pageViewData.pageData?.title,
        page_type: pageViewData.pageData?.pageType,
        fund_name: fundName,
      },
    };
    if (!waitFlag) {
      this.sendAnalytics(this.data);
    }
  }

  /**
   * Trigger Page View event for article page using the AnalyticsService.
   * called from article component
   */
  public triggerArticlePageView(articleData): void {
    const investmentTeam = articleData?.investmentteam;

    const customDimensions: CustomDimensions = {
      article_id: articleData?.articleID,
      publish_date: articleData?.date
        ? this.getFormatedDate(articleData.date)
        : undefined,
      investment_theme: articleData?.investmenttheme?.join('|'),
      investment_team: investmentTeam ? investmentTeam?.join('|') : 'other',
      publication_series: articleData?.publicationseries?.join('|'),
      specialty: articleData?.speciality?.join('|'),
      article_title: this.data.page_title,
      segmentation: articleData?.audienceExclusions?.join('|'),
      author: articleData?.authors?.join('|'),
      asset_class: articleData?.assetclassPage?.join('|'),
    };
    this.data = merge({}, this.data, {
      ...customDimensions,
      page_data: { ...customDimensions },
    });
    this.sendAnalytics(this.data);
  }

  /**
   * Set the product detail.
   * Called from product page layout.
   */
  public setProductAnalytics(
    productDetail: AnalyticsProductData,
    pageTitle: string,
    tab: string,
    fundId: string,
    pageName: string
  ): void {
    const customDimensions: CustomDimensions = {
      page_type: 'product',
      page_category: pageName,
      page_subcategory: productDetail?.fundCategory,
      vehicle: productDetail?.productType,
      page_title: pageTitle,
      fund_name: productDetail?.fundName,
      fund_brand: productDetail?.investmentManager,
      fund_class: productDetail?.shareClassName,
      fund_number: fundId,
      fund_tab: tab,
      fund_category: productDetail?.fundCategory,
      fund_ticker: productDetail?.fundTicker,
      fund_type: productDetail?.productType,
      investment_team: productDetail?.investmentGroup,
    };
    this.data = merge({}, this.data, {
      ...customDimensions,
      page_data: { ...customDimensions },
    });
    this.sendAnalytics(this.data);
  }

  /**
   * Set the User Data from the profile
   * Called after validated profile received.
   */
  private async setUserData(profileData: IUserProfile): Promise<void> {
    const customDimensions: CustomDimensions = {
      global_id: profileData?.profileInfo?.globalId,
      express_number: profileData?.profileInfo?.businessKey,
      user_sys_no: profileData?.profileInfo?.userSysNo,
      dealer_number: profileData?.profileInfo?.dealerNo,
      state_code: profileData?.profileInfo?.state,
      accounts_assigned: profileData?.profileInfo?.accountsAccess,
      firm_name: profileData?.profileInfo?.firm,
      ost_id: profileData?.profileInfo?.ostId,
      logged_in_status: profileData?.isLoggedIn
        ? IsLoginUser.LOGGEDIN
        : IsLoginUser.LOGGEDOUT,
      riva_id: profileData?.isLoggedIn
        ? profileData?.profileInfo?.rivaPartyId
        : undefined,
      user_group: profileData?.profileInfo?.userGroup,
      subsegment: profileData?.profileInfo?.webExperience,
      data_service_actor: profileData?.profileInfo?.actor,
      web_experience: profileData?.profileInfo?.firm, // for firm level experience tracking
      role_selected: profileData?.profileInfo?.role,
      role_type_track: profileData?.profileInfo?.role,
    };
    if (!profileData.isLoggedIn && !profileData.isIdentified) {
      // use user analytics in storage
      const userData = await this.storageService.retrieveAnalyticsData();
      if (userData) {
        customDimensions.global_id = userData.globalId;
        customDimensions.express_number = userData.expressNo;
        customDimensions.user_sys_no = userData.userSysNo;
      }
    }
    customDimensions.is_internal_user = this.isInternalUser(customDimensions);
    this.userData$.next(customDimensions);
  }

  /**
   * Returns string from PageAnalyticsMetadata array item
   * @param data - PageAnalyticsMetadata Array item
   */
  private joinAnalyticsMetadata(data: []): string {
    if (data && data?.length > 0) {
      return data.join(',');
    }
  }

  /**
   *
   * @param timeStamp actual timestamp
   * @returns date in format YYYY-MM-DD
   */
  private getFormatedDate(timeStamp: number): string {
    const date = new Date(timeStamp);
    const year = date.getFullYear().toString();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  private isInternalUser(customDimensions: CustomDimensions): string {
    const flag =
      customDimensions.global_id?.startsWith('FT') ||
      customDimensions.firm_name?.indexOf('Franklin') !== -1;
    return flag ? 'true' : 'false';
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
